<template>
    <div class="image-cropper">
        <div v-if="visibleLoading" class="image-cropper__content d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
        </div>

        <div v-show="!visibleLoading" class="image-cropper__container">
            <div class="image-cropper__content" v-show="!canDisplayZone">
                <div class="image-cropper__image">
                    <div ref="croppie"></div>
                    <div ref="overlay" class="image-cropper__overlay">
                        <slot name="overlay"></slot>
                    </div>
                </div>
            </div>

            <div v-show="!canDisplayZone" class="image-cropper__select px-4 py-2">
                <span class="image-cropper__select">
                    <file-select @change="onSelectFiles"/>{{ $t(`actions['other file']`) }}
                </span>
            </div>

            <div class="image-cropper__content" v-show="canDisplayZone">
                <drop-zone @drop="onSelectFiles">
                    <div class="image-cropper__dz-text d-flex align-center justify-center flex-column">
                        <p class="subtitle-1 mb-0">{{ $t(`actions['drag here']`) }}</p>
                        <span class="image-cropper__select">
                            <file-select @change="onSelectFiles"/>{{ $t(`actions['choose file']`) }}
                        </span>
                        <slot name="hint"></slot>
                    </div>
                </drop-zone>
            </div>
        </div>
    </div>
</template>

<script>
import DropZone from "storemoodify/lib/DropZone"
import FileSelect from "storemoodify/lib/FileSelect"
import debounce from "storemoodify/util/debounce"

const waitCropper = () => new Promise(resolve => setTimeout(resolve, 100))

export default {
    components: { DropZone, FileSelect },
    props: {
        src: {
            type: String,
            default: null,
        },

        viewport: {
            type: Object,
            default: () => ({ width: 200, height: 200, type: "square" }),
        },
    },

    data() {
        return {
            image: this.src,
            loaded: false,
            error: null,
        }
    },

    computed: {
        getOptions() {
            return {
                showZoomer: false,
                viewport: this.viewport,
            }
        },

        canDisplayZone() {
            return !this.image && !this.loaded
        },

        visibleLoading() {
            return !this.loaded && this.image
        },
    },

    mounted() {
        const debounceInit = debounce(() => this.init())
        this.image && this.init()

        this.$watch("viewport", debounceInit)
        this.$watch("src", () => {
            this.image = this.src
            this.init()
        })
    },

    methods: {
        init() {
            this.loaded = false
            this.$file = null
            this.$refs.croppie.src = ""
            this.$emit("init:start")
            return this.loadLib()
                .then(Croppie => this.createOrUpdate(Croppie))
                .then(() => {
                    this.loaded = true
                    this.$emit("load")
                    return this.$nextTick().then(waitCropper)
                })
                .then(() => this.$croppie.refresh())
                .then(() => this.$emit("inited"))
                .catch((e) => this.onLoadError(e))
        },

        getViewport() {
            const { width, height, useRatio, type } = this.viewport
            if (!useRatio) {
                return { width, height, type, ratio: 1 }
            }

            const clientHeight = this.$el.clientHeight - 60
            const clientWidth = this.$el.clientWidth - 60

            const ratioWidth = width / clientWidth
            const ratioHeight = height / clientHeight

            const ratio = Math.max(ratioWidth, ratioHeight, 1)

            return {
                ratio,
                type,
                width: width / ratio,
                height: height / ratio,
            }
        },

        onLoadError() {
            this.image = ""
        },

        createOrUpdate(Croppie) {
            this.destroy()
            const options = { ...this.getOptions, viewport: this.getViewport() }
            this.$croppie = new Croppie(this.$refs.croppie, options)
            this.updateOverlay()
            this.$croppie.$options = options
            return this.$nextTick().then(() => this.$croppie.bind({ url: this.image }))
        },

        destroy() {
            try {
                this.$croppie && this.$croppie.destroy()
            } catch (e) {
                // Do nothing
            }
            this.$croppie = null
        },

        clear() {
            this.loaded = false
            this.destroy()
            this.image = null
        },

        reset() {
            this.loaded = false
            this.image = this.src
            this.init()
        },

        loadLib() {
            return Promise.all([
                import("croppie").then(m => m.default),
                import("croppie/croppie.css"),
            ]).then(([Cropper]) => Cropper)
        },

        getBlob() {
            if (!this.$croppie) {
                return Promise.resolve(null)
            }

            const { ratio, width, height } = this.$croppie.$options.viewport

            return this.$croppie.result({
                type: "blob",
                circle: this.getOptions.viewport.type === "circle",
                size: {
                    width: width * ratio,
                    height: height * ratio,
                },
            }).then((res) => ({ cropped: res, original: this.$file || null }))
        },

        updateOverlay() {
            const { boundary } = this.$croppie.elements
            this.$refs.overlay && boundary.appendChild(this.$refs.overlay)
        },

        onSelectFiles(files) {
            const file = files[0]
            if (!file) return
            this.image = URL.createObjectURL(file)
            this.init()
                .then(() => this.$file = file)
        },
    },
}
</script>

<style lang="stylus">
.image-cropper {

    .croppie-container .cr-viewport, .croppie-container .cr-resizer {
        border-width 1px
    }

    .image-cropper__container {
        display flex
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .image-cropper__container .image-cropper__content {
        flex 1
    }
}

.image-cropper .drop-zone,
.image-cropper .image-cropper__dz-text {
    width 100%
    height 100%
}

.image-cropper .drop-zone {
    background #eceff1
}

.image-cropper .drop-zone_active {
    border: 2px dashed #88898b;
}

.image-cropper__select {
    position: relative;
    color: #1a76d2;
    cursor: pointer;
}

.image-cropper__select input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.image-cropper__content,
.image-cropper__image {
    width 100%
    height 100%
}
</style>
