<template>
    <v-dialog v-model="visible" :fullscreen="fullscreen" persistent max-width="600px">
        <v-card>
            <v-card-title>
                {{ $t(`headers['sheet adding']`) }}
            </v-card-title>
            <div class="px-4">
                <worksheet-form v-model="model" ref="form"/>
            </div>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="error" outlined @click="onCloseClick()">{{ $t("actions.cancel") }}</v-btn>
                <v-btn outlined ripple color="blue-grey darken-1" type="submit" @click="onSubmit()">{{ $t("actions.save") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import WorksheetForm from "../WorksheetForm"
import ModalForm from "storemoodify/lib/ModalForm"

export default {
    components: { WorksheetForm },
    mixins: [ModalForm],
    watch: {
        visible(val) {
            if (val) {
                this.clear()
            }
        },
    },
    computed: {
        fullscreen() {
            const { breakpoint } = this.$vuetify
            return breakpoint.xs || breakpoint.sm
        },
    },
    methods: {
        onCloseClick() {
            this.$emit("update:visible", false)
        },
    },
}
</script>
