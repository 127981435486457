<template>
    <div>
        <v-row>
            <v-col md="4" v-for="employee in getEmployees" :key="employee.id">
                <v-list-item :disabled="disabled" @click="onToggleEmployee(employee.id)" class="shift-form__item">
                    <!--eslint-disable-next-line-->
                    <template v-slot:default="{ active, toggle }">
                        <v-list-item-avatar>
                            <div class="shift-form__check"
                                 :class="{'shift-form__check_active': employee.checked, 'shift-form__check_disabled': employee.disabled}">
                                <v-icon color="white">mdi-check</v-icon>
                            </div>
                            <v-img :src="employee.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{employee.name}}</v-list-item-title>
                            <v-list-item-subtitle v-show="employee.checked">
                                <v-menu offset-y :ref="`menu_${employee.id}`">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" @click.stop @mousedown.stop>
                                            {{ $t(`rudiments['role']`) }} <span v-show="employee.role">{{employee.role | roleName}}</span>
                                            <span v-show="!employee.role">{{ $t(`placeholders['not chosen']`) }}</span>
                                            <v-icon class="ml-2" size="16">mdi-pencil</v-icon>
                                        </span>
                                    </template>
                                    <v-list>
                                        <v-subheader>{{ $t(`headers['employees role']`) }}</v-subheader>
                                        <v-list-item @click="onSelectRole(role.role, employee.id)"
                                                     v-for="role in getRoles" :key="role.role">
                                            <v-list-item-title>{{role.name}}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Format from "../../util/format"
import unobserve from "../../util/unobserve"

export default {
    props: {
        employees: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            model: unobserve(this.value),
        }
    },

    watch: {
        value(val) {
            this.model = unobserve(val)
        },
    },

    computed: {
        getRoles() {
            return [
                { role: "admin", name: this.$t(`roles['admin']`) },
                { role: "seller", name: this.$t(`roles['seller']`) },
                { role: "photographer", name: this.$t(`roles['photographer']`) },
                { role: "retoucher", name: this.$t(`roles['retoucher']`) },
            ]
        },

        getEmployees() {
            const { model } = this

            return this.employees.map(employee => {
                const index = this.getEmployeeIndex(employee.ID)
                const data = model[index] || {}

                return {
                    name: Format.getUserName(employee.Name),
                    id: employee.ID,
                    avatar: Format.getMedia(employee.Avatar.Cropped),
                    checked: index !== -1,
                    role: data.Role || "",
                }
            })
        },
    },

    methods: {
        onToggleEmployee(id) {
            const index = this.getEmployeeIndex(id)
            if (index === -1) {
                this.model.push({ UserID: id, Role: "" })
                this.$nextTick().then(() => this.onSelectEmployee(id))
            } else {
                this.model.splice(index, 1)
            }

            this.commit()
        },

        onSelectEmployee(id) {
            try {
                const [menu] = this.$refs[`menu_${id}`]
                menu.mouseEnterHandler()
            } catch (e) {
                // Do nothing
            }
        },

        getEmployeeIndex(id) {
            return this.model.findIndex(item => item.UserID === id)
        },

        onSelectRole(role, employee) {
            const index = this.getEmployeeIndex(employee)
            this.model[index].Role = role
            this.commit()
        },

        commit() {
            this.$emit("input", this.model)
        },
    },
}
</script>
<style lang="stylus">
    .shift-form__item {
        height 64px
    }

    .shift-form__check {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #1767c0;
        opacity 0
        z-index: 2;
        border-radius: 100%;
    }

    .shift-form__item:hover .shift-form__check {
        opacity 0.5
    }

    .shift-form__check.shift-form__check_active {
        opacity 0.8 !important
    }
</style>
