<template>
    <v-container fluid>
        <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
        </div>

        <div v-show="!loading">
            <v-row align="center">
                <v-col cols="5" md="6">
                    <h4 class="headline" :class="{'subtitle-1': $vuetify.breakpoint.smAndDown}">
                        {{$t(`headers['retouchers count']`, [getEmployees.length])}}
                    </h4>
                </v-col>
                <v-col cols="7" md="6" align="end">
                    <div>
                        <v-btn text @click="onClickEdit(place.RetouchersShare)" ripple color="primary">
                            <span v-if="$vuetify.breakpoint.smAndDown"><v-icon left>mdi-pencil</v-icon></span>
                            <span v-else>
                                <v-icon left>mdi-pencil</v-icon>
                                {{ $t(`actions['update share']`) }}
                            </span>
                        </v-btn>
                        <v-btn text @click="onClickAdd()" ripple color="primary">
                            <span v-if="$vuetify.breakpoint.smAndDown"><v-icon left>mdi-plus</v-icon></span>
                            <span v-else>
                                <v-icon left>mdi-plus</v-icon>
                                {{ $t("actions.add") }}
                            </span>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <employees-table disable-edit :employees="getEmployees" :error="errorLoading" @remove="onClickRemove">
                <template slot="empty">
                    {{ $t(`messages['no retouchers']`) }}
                </template>
            </employees-table>
        </div>
        <employee-share-modal :visible.sync="visibleEditShare" v-model="shareModel" @submit="confirmEdit()"/>
    </v-container>
</template>

<script>
import EmployeesMixin from "./employeesMixin"
import EmployeesTable from "./EmployeesTable"
import EmployeeShareModal from "./EmployeeShareModal"

export default {
    components: { EmployeesTable, EmployeeShareModal },
    mixins: [EmployeesMixin("retoucher")],
}
</script>
