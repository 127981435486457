<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose user']`)"
                      :items="users"
                      :rules="rules"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="userToOption"
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import unobserve from "@/util/unobserve"
import Autocomplete from "@/components/Autocomplete"

export default {
    components: { Autocomplete },
    props: {
        value: [Array, Object],
        multiple: Boolean,
        rules: Array,
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            model: !this.value ? this.value : unobserve(this.value),
            isLoading: false,
            users: [],
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            this.model = this.value
        },
    },
    methods: {
        ...mapActions("user", ["findByQuery"]),
        userToOption(user) {
            return {
                id: user.ID,
                name: `${Format.getUserName(user.Name)}`,
            }
        },

        onChangeQuery(q) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            this.findByQuery({ query: q, params: this.params }).then(users => {
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.users = users
                }
            })
        },
    },
}
</script>
