<template>
    <v-dialog v-model="visibleModal" persistent :max-width="getWidth">
        <v-card>
            <image-cropper :viewport="viewport"
                           @load="onLoadImage()"
                           ref="cropper"
                           :style="{height: `${getHeight}px`}">
                <template slot="hint">
                    <slot name="hint"></slot>
                </template>
            </image-cropper>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="error" outlined @click="close()">{{ $t("actions.cancel") }}</v-btn>
                <v-btn outlined ripple
                       color="blue-grey darken-1"
                       type="submit"
                       :disabled="disabledSubmit"
                       @click="onSubmit()">
                    {{ $t("actions.save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalFormMixin from "./ModalFormMixin"
import ImageCropper from "../ImageCropper"

const defaultWidth = 600
const defaultHeight = 250
const maxWidth = 1128
const maxHeight = 294

export default {
    components: { ImageCropper },
    mixins: [ModalFormMixin],

    props: {
        multiline: Boolean,
        viewport: Object,
    },

    watch: {
        visible(val) {
            !val && this.clear()
        // setTimeout(() => !val && this.$refs.cropper.clear())
        },
    },

    data() {
        return {
            disabledSubmit: true,
        }
    },

    computed: {
        getRatio() {
            const { width, height, useRatio } = this.viewport
            if (!useRatio) {
                return 1
            }

            if (maxWidth > width && maxHeight > height) {
                return 1
            }

            const ratioWidth = width / maxWidth
            const ratioHeight = height / maxHeight
            return Math.max(ratioHeight, ratioWidth)
        },

        getWidth() {
            const { width } = this.viewport
            return Math.max(width / this.getRatio + 60, defaultWidth)
        },

        getHeight() {
            const { height } = this.viewport
            return Math.max(height / this.getRatio + 60, defaultHeight)
        },
    },

    methods: {
        onLoadImage() {
            this.disabledSubmit = false
        },

        clear() {
            setTimeout(() => {
                this.disabledSubmit = true
                this.$refs.cropper.clear()
            }, 50)
        },

        onSubmit() {
            this.$refs.cropper.getBlob()
                .then(blob => this.$emit("submit", blob))
        },
    },
}
</script>
