<template>
    <v-container fluid>
        <div v-if="visibleLoading" class="d-flex align-center justify-center my-8 flex-column">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
            <p class="mt-4">{{ $t(`messages['loading data']`) }}</p>
        </div>

        <div v-show="canVisibleList">
            <div class="d-flex">
                <h4 class="flex headline">{{ $t(`headers['reports list']`) }}</h4>
                <v-btn @click="onClickCreateReport()" outlined ripple color="blue-grey darken-1">{{$t(`actions['fill report']`)}}</v-btn>
            </div>

            <div class="text-center py-8" v-show="isEmptyList">
                <h4 class="flex headline">{{ $t(`messages['no reports']`) }}</h4>
            </div>

            <v-simple-table v-show="!isEmptyList">
                <template slot="default">
                    <thead>
                        <tr>
                            <th>{{ $t(`tables['date']`) }}</th>
                            <th>{{ $t(`tables['sales amount']`) }}</th>
                            <th>{{ $t(`tables['sales total']`) }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="report in getReportsList" :key="report.id">
                            <td>
                                <b>{{report.date | unixDateTime("DD.MM.YYYY")}}</b>
                            </td>
                            <td>{{report.sales.count}}</td>
                            <td>{{report.sales.total | currency}}</td>
                            <td class="text-right">
                                <!--                            <v-tooltip left>-->
                                <!--                                <template v-slot:activator="{ on }">-->
                                <!--                                    <v-btn text icon color="blue-grey" class="mr-1" v-on="on">-->
                                <!--                                        <v-icon>mdi-eye</v-icon>-->
                                <!--                                    </v-btn>-->
                                <!--                                </template>-->
                                <!--                                <span>Просмотреть отчёт</span>-->
                                <!--                            </v-tooltip>-->
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon color="blue-grey" class="mr-1" v-show="canVisibleCopy"
                                               @click="onClickCopyReport(report.date)" v-on="on">
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t(`actions['copy to date']`) }}</span>
                                </v-tooltip>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn text icon color="blue-grey" class="mr-1"
                                               @click="onClickEditReport(report.id)"
                                               v-on="on">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t("actions.edit") }}</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </div>

        <div v-show="canVisibleForm">
            <report-form v-model="reportModel"
                         :products="getProductsList"
                         :employees="employees"
                         @cancel="onCancelForm"
                         @submit="onSubmitReport"/>
        </div>
    </v-container>
</template>

<script>
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import ReportForm from "./ReportForm"

const defaultReportsFilter = () => {
    const now = dayjs()
    return {
        end: now.endOf("day").unix(),
        begin: now.subtract(2, "week").startOf("day").unix(),
    }
}

const calculateReportSales = (sales) => sales.reduce((res, sale) => {
    res.count += parseInt(sale.Count) || 0
    res.total += parseFloat(sale.Sum) || 0
    return res
}, { count: 0, total: 0 })

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { ReportForm },

    props: {
        place: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            visibleForm: false,
            visibleLoading: false,
            reportModel: {},
            employees: [],
            reports: [],
        }
    },

    computed: {
        ...mapGetters("directory", ["getProducts"]),
        getProductsList() {
            const placeProducts = this.place.ProductIds;
            return this.getProducts.filter(product => placeProducts.includes(product.ID))
        },
        canVisibleList() {
            return !this.visibleLoading && !this.visibleForm
        },
        canVisibleForm() {
            return !this.visibleLoading && this.visibleForm
        },
        getReportsList() {
            return this.reports.map((report) => {
                return {
                    id: report.ID,
                    date: report.Date,
                    sales: calculateReportSales(report.Sales),
                }
            })
        },
        isEmptyList() {
            return this.reports.length === 0
        },
        canVisibleCopy() {
            const today = dayjs().startOf("date").unix()
            return !this.reports.find(r => {
                const date = new Date(r.Date * 1000)
                return dayjs(date).startOf("day").unix() === today
            })
        },
    },

    created() {
        this.reload()
    },

    methods: {
        ...mapActions("directory", ["fetchProducts"]),
        ...mapActions("place", ["setDailyReport", "findDailyReports", "copyDailyReport"]),
        ...mapActions({
            "getUsers": "user/getByIds",
        }),

        onClickCreateReport() {
            this.visibleForm = true
            this.reportModel = {}
        },

        fetchEmployees() {
            const ids = [].concat(this.place.Admins).concat(this.place.Photographers)
            return this.getUsers(ids).then(employees => {
                this.employees = employees
            })
        },

        reload() {
            this.visibleLoading = true
            Promise.all([
                this.fetchProducts(),
                this.fetchEmployees(),
                this.loadReports(),
            ]).then(() => {
                this.visibleLoading = false
            })
        },

        loadReports() {
            return this.findDailyReports({
                placeId: this.place.ID,
                range: defaultReportsFilter(),
            }).then(reports => {
                this.reports = reports
            })
        },

        onClickEditReport(id) {
            this.reportModel = this.reports.find(r => r.ID === id)
            this.visibleForm = true
        },

        onCancelForm() {
            this.visibleForm = false
        },

        onSubmitReport() {
            const data = {
                PlaceID: this.place.ID,
                ...this.reportModel,
            }

            this.$dialog.loading({ text: this.$t(`messages['report saves']`) }).then((dialog) => {
                this.setDailyReport(data)
                    .then(() => this.onSaveReportDone())
                    .catch((e) => this.onSaveReportError(e))
                    .finally(() => dialog.close())
            })
        },

        onSaveReportDone() {
            this.onCancelForm()
            this.reload()
        },

        onSaveReportError() {
            this.$dialog.error({
                title: this.$t(`errors['error']`),
                text: this.$t(`errors['report save error']`),
            })
        },

        onClickCopyReport(date) {
            const payload = { PlaceID: this.place.ID, DateFromCopy: parseInt(date) }
            this.$dialog.loading({ text: this.$t(`messages['report copies']`) }).then((dialog) => {
                return this.copyDailyReport(payload)
                    .then((report) => this.onCopyReportDone(report))
                    .catch((e) => this.onCopyReportError(e))
                    .finally(() => dialog.close())
            })
        },

        onCopyReportDone(report) {
            this.reports.unshift(report)
            this.onClickEditReport(report.ID)
            this.$dialog.message.success(this.$t(`messages['report saved']`))
        },

        // eslint-disable-next-line no-unused-vars
        onCopyReportError() {
            this.$dialog.error({
                title: this.$t(`errors['error']`),
                text: this.$t(`errors['report copy error']`),
            })
        },
    },
}
</script>

<style lang="stylus">
    .reports-controller__shift-error {
        color: #ff5252
    }
</style>
