<template>
    <div>
        <div class="d-flex align-center mb-8">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-btn @click="onClickCancel()" text icon color="dark" class="mr-3" v-on="on">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t(`actions['to reports']`) }}</span>
            </v-tooltip>

            <h4 class="flex headline">
                {{ $t(`rudiments['report for']`, [getFormattedDate]) }}
                <v-btn @click="onClickChangeDate()" class="ml-4" text small color="primary" v-show="!isEditReport">
                    {{ $t(`actions['change date']`) }}
                </v-btn>
            </h4>
        </div>

        <h4 class="flex title d-flex">
            {{ $t(`headers['choose shift']`) }}
            <span v-show="!isEditReport" class="red--text">({{ $t(`messages['shift editable for']`) }})</span>
            <span class="d-flex red--text" v-if="isEditReport && !isDisabledShiftForm">
                ({{ $t(`messages['shift lock in']`) }} <time-countdown @done="onExpiredChangeShift"
                                                                       class="ml-2" :format="$t(`format['time']`)"
                                                                       :expired="disabledChangeShiftAt"/>)
            </span>
            <span class="d-flex red--text" v-if="isEditReport && isDisabledShiftForm">
                ({{ $t(`messages['shift locked']`) }})
            </span>
        </h4>

        <report-shift-form :disabled="isDisabledShiftForm" v-model="shift" :employees="getEmployees"/>

        <div v-show="getShiftEmployees.length > 0" class="mt-8">
            <v-divider class="mb-8"/>

            <h4 class="flex title">{{ $t(`messages['fill report']`) }}</h4>
            <div class="title red--text" v-show="!isEditReport">
                {{ $t(`messages['sales editable for']`) }}
            </div>
            <div class="title d-flex red--text" v-if="isEditReport && !isDisabledReportForm">
                {{ $t(`messages['sales locked in']`) }}
                <time-countdown @done="onExpiredChangeReport" class="ml-2" :format="$t(`format['time']`)"
                                :expired="disabledChangeReportAt"/>
            </div>
            <div class="title d-flex red--text" v-if="isEditReport && isDisabledReportForm">
                {{ $t(`messages['shift locked']`) }}
            </div>

            <report-products-form v-model="sales" :disabled="isDisabledReportForm" :employees="getShiftEmployees"
                                  :products="products"/>
        </div>

        <div class="mt-8 text-right" v-show="!isDisabledReportForm">
            <v-btn rippled outlined @click="onSubmit()" color="blue-grey darken-1">
                {{isEditReport ? $t(`actions['save report']`) : $t(`actions['create report']`)}}
            </v-btn>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs"
import ReportShiftForm from "./ReportShiftForm"
import ReportProductsForm from "./ReportProductsForm"
import TimeCountdown from "storemoodify/lib/TimeCountdown"

const ALLOW_CHANGE_SHIFT_TIME = 3600 * 3 * 1000
const ALLOW_CHANGE_REPORT_TIME = 3600 * 36 * 1000

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { ReportShiftForm, ReportProductsForm, TimeCountdown },
    props: {
        products: {
            type: Array,
            default: () => [],
        },
        employees: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => ({ Employees: [], Sales: [], Date: null }),
        },
    },

    data() {
        return {
            shift: [],
            sales: [],
            date: dayjs().unix(),
            disabledChangeShiftAt: null,
            disabledChangeReportAt: null,
        }
    },

    created() {
        this.$watch("value.Employees", this.onChangeShift, { immediate: true })
        this.$watch("value.Sales", this.onChangeSales, { immediate: true })
        this.$watch("value.Date", this.onChangeDate, { immediate: true })
        this.$watch("value.CreatedAt", this.updateDisabledDate, { immediate: true })
    },

    computed: {
        getEmployees() {
            const { employees } = this
            const ids = employees.map(e => e.ID)
            return employees.filter((e, i) => i === ids.indexOf(e.ID))
        },

        getShiftEmployees() {
            const { employees, shift } = this
            return shift.map(item => {
                const employee = employees.find(e => e.ID === item.UserID)
                return Object.assign({ ShiftRole: item.Role }, employee)
            })
        },

        getFormattedDate() {
            const date = new Date(this.date * 1000)
            return dayjs(date).startOf("day").format("DD.MM.YYYY")
        },

        isEditReport() {
            return "ID" in this.value
        },

        isDisabledShiftForm() {
        // Update property for change disabledChangeShiftAt
        // eslint-disable-next-line no-unused-vars
        //     const disabledAt = this.disabledChangeShiftAt

            if (!this.isEditReport) {
                return false
            }

            const created = this.value.CreatedAt * 1000
            return new Date(created + ALLOW_CHANGE_SHIFT_TIME) < Date.now()
        },

        isDisabledReportForm() {
        // Update property for change disabledChangeReportAt
        //     const disabledAt = this.disabledChangeReportAt
            if (!this.isEditReport) {
                return false
            }

            const created = this.value.CreatedAt * 1000
            return new Date(created + ALLOW_CHANGE_REPORT_TIME) < Date.now()
        },
    },

    methods: {
        onClickChangeDate() {
            this.$dialog.datepicker({ title: this.$t(`labels['choose date']`) }).then(res => {
                if (res !== false) {
                    this.date = dayjs(res, "YYYY-MM-DDD").startOf("day").unix()
                }
            })
        },

        onSubmit() {
            this.$emit("input", { Employees: this.shift, Sales: this.sales, Date: this.date })
            this.$emit("submit")
        },

        onChangeDate(val) {
            if (val) {
                this.date = dayjs(new Date(val * 1000)).startOf("day").unix()
            } else {
                this.date = dayjs().startOf("day").unix()
            }
        },

        onChangeSales(val) {
            this.sales = val
        },

        onChangeShift(val) {
            this.shift = val || []
        },

        onClickCancel() {
            this.$emit("cancel")
        },

        updateDisabledDate() {
            if (this.isEditReport) {
                const created = this.value.CreatedAt * 1000
                this.disabledChangeShiftAt = new Date(created + ALLOW_CHANGE_SHIFT_TIME)
                this.disabledChangeReportAt = new Date(created + ALLOW_CHANGE_REPORT_TIME)
            }
        },

        onExpiredChangeShift() {
            this.disabledChangeShiftAt = null
        },

        onExpiredChangeReport() {
            this.disabledChangeReportAt = null
        },
    },
}
</script>
