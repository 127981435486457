<template>
    <v-container fluid>
        <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-4"
                indeterminate/>
        </div>

        <div v-show="!loading">
            <v-row align="center">
                <v-col cols="7" md="6">
                    <h4 class="headline" :class="{'subtitle-1': $vuetify.breakpoint.smAndDown}">
                        {{$t(`headers['photographers count']`, [getEmployees.length])}}
                    </h4>
                </v-col>
                <v-col cols="5" md="6" align="end">
                    <div>
                        <v-btn @click="onClickAdd()" text :x-small="$vuetify.breakpoint.xsOnly" ripple color="primary">
                            <v-icon left>mdi-plus</v-icon>
                            {{ $t("actions.add") }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <employees-table :employees="getEmployees" :error="errorLoading" @edit="onClickEdit" @remove="onClickRemove"
                             @reload="loadList()">
                <template slot="empty">
                    {{ $t(`messages['no photographers']`) }}
                </template>
            </employees-table>
        </div>

        <employee-share-modal :visible.sync="visibleEditShare" v-model="shareModel" @submit="confirmEdit()"/>
    </v-container>
</template>

<script>
import EmployeesTable from "./EmployeesTable"
import EmployeeShareModal from "./EmployeeShareModal"
import EmployeesMixin from "./employeesMixin"


export default {
    components: { EmployeesTable, EmployeeShareModal },
    mixins: [EmployeesMixin("photographer")],
}
</script>
