<template>
    <v-dialog v-model="visibleModal" persistent max-width="600px">
        <v-card>
            <state-spinner ref="spinner" :visible.sync="visibleSpinner" size="space"/>
            <v-btn icon class="recognize-promo__close" @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-list-item dense>
                <v-list-item-content>
                    <v-list-item-title class="headline text-center">
                        <slot name="title">{{ $t(`headers['promo activation']`) }}</slot>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <div v-show="launchError" class="text-center subtitle-1 py-6 red--text">
                {{ $t(`errors['cam error']`) }}
            </div>

            <div v-if="visibleLoader" class="d-flex justify-center py-8">
                <v-progress-circular
                    :size="50"
                    color="blue-grey darken-4"
                    indeterminate/>
            </div>

            <div v-show="!visibleLoader && !launchError">
                <camera-source
                    v-show="!launchError"
                    :controls="{switch: true, capture: false}"
                    @launched="onLaunchCamera"
                    @error="onErrorLaunchCamera"
                    ref="camera"
                    facing-mode="environment"
                />

                <div class="py-4 px-4" v-show="manual">
                    <h2 class="subtitle-1" v-show="!launchError">{{ $t(`actions['or enter code below']`) }}</h2>
                    <h2 class="subtitle-1 text-center" v-show="launchError">{{ $t(`actions['enter code below']`) }}</h2>
                    <promo-form @submit="onSubmitForm" ref="form"/>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions } from "vuex"
import CameraSource from "storemoodify/lib/CameraSource"
import StateSpinner from "storemoodify/lib/StateSpinner"
import { ResourceError } from "storemood-connection"
import ModalFormMixin from "./ModalFormMixin"
import PromoForm from "../PromoForm"

export default {
    components: { CameraSource, PromoForm, StateSpinner },
    mixins: [ModalFormMixin],

    props: {
        manual: {
            type: Boolean,
            default: true,
        },
        validator: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            launchError: false,
            launched: false,
            visibleSpinner: false,
        }
    },

    watch: {
        visibleModal(val) {
            this.$nextTick(() => val ? this.startCamera() : this.stopCamera())
        },
    },

    computed: {
        visibleLoader() {
            return !this.launched && !this.launchError
        },
    },

    methods: {
        ...mapActions({
            decodePromo: "promo/decode",
            findPromoCode: "promo/findCode",
        }),

        triggerSuccess() {
            setTimeout(() => this.visibleSpinner = true, 200)
            this.$refs.spinner.success()
        },

        startCamera() {
            this.launched = false
            this.launchError = false
            this.visibleSpinner = false
            this.$refs.camera.launch()
        },

        stopCamera() {
            setTimeout(() => {
                this.$reader && this.$reader.stopStreams()
                this.$refs.camera.stop()
                this.$refs.form.clear()
            })
        },

        onLaunchCamera({ stream }) {
            this.launched = true
            this.createReader(stream)
        },

        onErrorLaunchCamera() {
            this.launchError = true
        },

        createReader(stream) {
            import("@zxing/library").then(({ BrowserQRCodeReader }) => {
                this.$reader = new BrowserQRCodeReader()
                this.bindReader(stream)
            })
        },

        bindReader(stream) {
            let ignore = false
            this.$reader.decodeFromStream(stream, null, (data) => {
                if (data !== null && !ignore) {
                    ignore = true
                    this.validateCapturedCode(data.text)
                        .finally(() => ignore = false)
                }
            })
        },

        validateCapturedCode(code) {
            if (this.validator === "promo") {
                return this.decodePromo(code)
                    .then(rawCode => this.validate(rawCode))
            } else {
                this.onSuccessValidate(code)
            }
        },

        validate(code) {
            this.findPromoCode(code)
                .then(() => this.onSuccessValidate(code))
                .catch((e) => this.onErrorValidate(e))
        },

        onSubmitForm({ code }) {
            this.validate(code)
        },

        onSuccessValidate(code) {
            this.$emit("capture", code)
        },

        onErrorValidate(e) {
            new ResourceError(e).ifCode("not found", () => {
                this.$refs.form.setErrors({ code: [this.$t(`errors['wrong code']`)]})
            }).else(() => {
                this.$refs.form.setErrors({ code: [this.$t(`errors['try later']`)]})
            })
        },
    },
}
</script>
<style lang="stylus">
    .recognize-promo__close {
        right: 4px;
        position: absolute;
        top: 6px;
        z-index: 2;
    }
</style>
