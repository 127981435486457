<template>
    <v-datetime-picker
        v-model="model"
        :label="label"
        :error="error"
        :error-messages="errorMessages"
        :time-picker-props="getTimePickerProps"
        :date-picker-props="getDatePickerProps"
        color="blue-grey lighten-1"
        class="form-filter__timepicker"
        date-format="dd.MM.yyyy"
        :clear-text="$t(`actions.discard`)"
        :ok-text="$t(`actions.apply`)"
        tabs-slider-color="orange"
        tabs-background-color="blue-grey lighten-5"
        ok-color="primary">
        <template slot="dateIcon">
            <v-icon>mdi-calendar-month</v-icon>
        </template>
        <template slot="timeIcon">
            <v-icon>mdi-clock-outline</v-icon>
        </template>
    </v-datetime-picker>
</template>

<script>
import dayjs from "dayjs"

const isEqual = (val, oldVal) => String(val) === String(oldVal)

export default {
    props: {
        value: {
            type: [Date, String],
            default: null,
        },
        label: {
            type: String,
            default: "",
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: String,
            default: "",
        },
    },

    data() {
        return { model: null }
    },

    computed: {
        getTimePickerProps() {
            return { format: "24hr" }
        },
        getDatePickerProps() {
            return {
                locale: "ru-Latn",
                max: new Date().toISOString(),
                min: new Date(2019, 1, 1).toISOString(),
                firstDayOfWeek: "1",
            }
        },
    },

    watch: {
        model(val) {
            if (!isEqual(val, this.value)) {
                this.$emit("input", val)
            }
        },
        value(val) {
            const date = dayjs(val)
            this.model = date.isValid() ? date.toDate() : null
        },
    },
}
</script>
