<template>
    <div class="promo-form">
        <form @submit.prevent="onSubmit()">
            <div class="d-flex align-center">
                <div class="flex">
                    <v-text-field
                        :error-messages="firstError('code')"
                        :error="hasError('code')"
                        v-model="model.code"
                        placeholder="XXXXXXXXXX"></v-text-field>
                </div>
                <div class="pl-2">
                    <v-btn type="submit" text small color="primary">{{ $t("actions.activate") }}</v-btn>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Form from "storemoodify/lib/Form"

export default {
    mixins: [Form],
    methods: {
        defaultValue() {
            return { code: "" }
        },
        getConstraints() {
            return {
                code: {
                    presence: {
                        allowEmpty: false,
                        message: this.$t(`errors['enter code']`),
                    },
                },
            }
        },
    },
}
</script>
