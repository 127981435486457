<template>
    <v-container fluid class="photos-control">
        <div class="mt-6">
            <photogroups-filter
                @submit="onSubmitFilter"
                v-model="filter"
                :photographers="!isPhotographerScope"
                :photographer-filter="{PlaceID: placeId}"/>

            <div v-if="isLoading" class="d-flex justify-center mt-8 mb-8 flex-column align-center">
                <v-progress-circular
                    :size="50"
                    color="blue-grey darken-4"
                    indeterminate/>
                <p class="headline mt-4">{{ $t(`messages['loading data']`) }}</p>
            </div>

            <div v-show="isEmpty && !appendLoading && !isLoading" class="text-center mb-4 py-3">
                <p class="subtitle-1">{{ $t(`messages['no photos']`) }}</p>
            </div>

            <photo-actions
                v-if="!photographerScope"
                :place-id="placeId"
                :photos="getPhotosList"
                :photogroups="getPhotogroupsList"
                :selected-photos.sync="selected.photos"
                :selected-photogroups.sync="selected.photogroups"
                compose-by-date
                @reload="loadPhotogroups()"
            />

            <grid-photogroups @fetch:bottom="appendPhotogroups()"
                              :selected.sync="selected"
                              :readonly="photographerScope"
                              v-model="photogroups">
                <template slot="preview-panel" slot-scope="props">
                    <lightbox-panel v-if="props.photo" :photo="props.photo.self"/>
                </template>
            </grid-photogroups>

            <div v-if="appendLoading" class="d-flex justify-center mt-6 mb-6">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate/>
            </div>
        </div>

    </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import GridPhotogroups from "../photogroups/GridPhotogroups"
import PhotogroupsFilter from "../photogroups/Filter"
import LightboxPanel from "../photogroups/LightboxPanel"
import PhotoActions from "./PhotoActions"
import * as Adapters from "../../util/adapters"
import unobserve from "../../util/unobserve"

const defaultPhotoreport = () => ({
    Name: {},
    Features: {
        ClientName: "",
        Emails: [],
        Phones: [],
        CheckOutDate: null,
        RoomNumber: null,
    },
})

const PHOTOGROUPS_LIMIT = 20

export default {
    name: "PhotogroupsControl",
    components: {
        PhotoActions,
        LightboxPanel,
        GridPhotogroups,
        PhotogroupsFilter,
    },
    props: {
        placeId: {
            type: String,
            default: "",
        },
        photographerScope: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
            appendLoading: false,
            canLoadNext: true,
            visibleSelectPhotoreport: false,
            visibleCreatePhotoreport: false,
            photogroups: [],
            selectedPhotoreport: null,
            photoreportModel: defaultPhotoreport(),
            filter: {},
            submittedFilter: {},
            selected: {
                photos: [],
                photogroups: [],
            },
        }
    },

    created() {
        this.appendPhotogroups()
    },

    computed: {
        ...mapGetters("common", ["getUser"]),
        isEmpty() {
            return this.photogroups.length === 0
        },

        isLoading() {
            return this.loading
        },

        getOffset() {
            return this.photogroups.length
        },

        isPhotographerOnly() {
            return !this.getUser.JS && !this.getUser.AdminingPlaces.includes(this.placeId)
        },

        isPhotographerScope() {
            return this.placeId === "" && this.photographerScope
        },

        getPhotographerId() {
            return this.getUser.ID
        },

        getFilter() {
            const { photographer, query, place } = this.submittedFilter
            const result = { Query: query || "" }

            if (place) {
                result.PlaceID = place.ID
            }

            if (photographer) {
                result.PhotographerID = photographer.ID
            }

            if(this.isPhotographerOnly) {
                result.PhotographerID = this.getPhotographerId
            }

            if (this.isPhotographerScope) {
                result.PhotographerID = this.getPhotographerId
            } else {
                result.PlaceID = this.placeId
            }

            return result
        },

        getPhotoreportFilter() {
            return { PlaceID: this.placeId }
        },

        getPhotosList() {
            return this.photogroups.reduce((res, photogroup) => res.concat(photogroup.photos), [])
        },

        getPhotogroupsList() {
            return this.photogroups
        },
    },

    methods: {
        ...mapActions({
            findPhotogroups: "photogroup/search",
        }),

        getPhotogroups() {
            return this.findPhotogroups({
                Limit: PHOTOGROUPS_LIMIT,
                Offset: this.getOffset,
                ...this.getFilter,
            }).then((res) => {
                this.canLoadNext = PHOTOGROUPS_LIMIT <= res.length
                return Adapters.photogroups(res)
            })
        },

        loadPhotogroups() {
            this.loading = true
            this.photogroups = []
            this.getPhotogroups()
                .then(res => this.photogroups = res)
                .finally(() => this.loading = false)
        },

        appendPhotogroups() {
            if (this.appendLoading || !this.canLoadNext) {
                return
            }
            this.appendLoading = true
            this.getPhotogroups()
                .then(res => this.photogroups.push(...res))
                .finally(() => this.appendLoading = false)
        },

        onSubmitFilter() {
            this.submittedFilter = unobserve(this.filter)
            this.loadPhotogroups()
        },
    },
}
</script>
<style lang="stylus">
    .photos-control {
        .photos-control__actions {
            position: sticky;
            top: 64px;
            z-index: 6;
            background: #fff;
        }
    }
</style>
