<template>
    <v-container fluid class="px-0">
        <table-error @again="onReload()" v-show="error"/>

        <div class="mt-8 mb-8 text-center" v-show="isEmpty">
            <h4 class="headline">
                <slot name="empty"></slot>
            </h4>
        </div>

        <v-simple-table v-show="!isEmpty">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">{{ $t(`tables['name']`) }}</th>
                        <th class="text-left">{{ $t(`tables['share']`) }}</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="employee in model" :key="employee.id">
                        <td>
                            <v-list-item class="ml-0 pl-0">
                                <v-list-item-avatar>
                                    <v-img :src="employee.image"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">{{employee.name}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </td>
                        <td>{{employee.share}}%</td>
                        <td class="text-right">
                            <v-tooltip v-if="!disableEdit" left>
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon color="blue-grey" v-on="on"
                                           @click="onClickEdit(employee.id, employee.share)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t(`actions['edit terms']`) }}</span>
                            </v-tooltip>

                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn text icon color="blue-grey"
                                           @click="onClickRemove(employee.id)"
                                           v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t(`actions['fire']`) }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
import TableError from "../TableError"

export default {
    components: { TableError },
    props: {
        employees: {
            type: Array,
            default: () => ([]),
        },
        error: {
            type: Boolean,
            default: false,
        },
        disableEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: this.employees
        }
    },
    computed: {
        isEmpty() {
            return this.employees.length === 0
        },
    },
    watch:{
        employees: {
            deep: true,
            handler(){
                this.model = this.employees
            }
        }
    },
    methods: {
        onClickEdit(id, share) {
            this.$emit("edit", share, id)
        },
        onClickRemove(id) {
            this.$emit("remove", id)
        },
        onReload() {
            this.$emit("reload")
        },
    },
}
</script>
