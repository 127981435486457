<template>
    <v-sheet elevation="14">
        <v-row no-gutters>
            <template v-for="(card, i) in getValue">
                <div :key="i" class="col d-flex flex-row" :class="{'col-12': $vuetify.breakpoint.smAndDown}">
                    <div class="py-4 px-2 flex">
                        <div class="caption text-uppercase">{{card.title}}</div>
                        <div class="title">{{card.today}}</div>
                        <div class="caption blue-grey--text lighten-2">
                            <v-icon size="20" :color="`${card.color} darken-3`" class="ml-n1" v-if="card.arrow">
                                {{ "mdi-arrow-" + card.arrow }}
                            </v-icon>
                            <span v-html="card.text"></span>
                        </div>
                    </div>
                    <v-divider vertical></v-divider>
                </div>
            </template>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    props: {
        value: {
            type: Object,
        },
    },

    computed: {
        getTitles() {
            return {
                uploads: this.$t(`headers['uploads']`),
                sales: this.$t(`headers['sales']`),
                worksheets: this.$t(`headers['worksheets']`),
                viewPhotos: this.$t(`headers['view photos']`),
            }
        },

        getValue() {
            const keys = Object.keys(this.value)
            const titles = this.getTitles

            return keys.reduce((res, key) => {
                const { today, yesterday } = this.value[key]
                return res.concat({
                    key,
                    today,
                    yesterday,
                    title: titles[key] || key,
                    ...this.getAttrs(today, yesterday),
                })
            }, [])
        },
    },

    methods: {
        getAttrs(today, yesterday) {
            const todayIsUpper = today > yesterday
            const todayIsLower = today < yesterday
            const isEqual = !todayIsLower && !todayIsUpper
            const percentDiff = parseInt((today - yesterday) / (yesterday || 1) * 100)

            const arrow = isEqual ? "" : todayIsUpper ? "up" : "down"
            const color = isEqual ? "" : todayIsUpper ? "green" : "red"

            return {
                arrow, color,
                text: this.getCardText(today, yesterday, percentDiff, color),
                percentDiff: percentDiff,
            }
        },

        getCardText(today, yesterday, percentDiff, color) {
            if (today > yesterday) {
                return this.$t(`rudiments['more than yesterday']`, [yesterday]) + `<b class="${color}--text">${percentDiff}%</b>`
            } else if (yesterday > today) {
                return this.$t(`rudiments['less than yesterday']`, [yesterday]) + `<b class="${color}--text">${percentDiff}%</b>`
            }

            return this.$t(`rudiments['same as yesterday']`)
        },
    },
}
</script>
