<template>
    <form @submit.prevent="onSubmit()">
        <v-row>
            <v-col cols="12" md="4">
                <photographer-autocomplete v-if="photographers" :params="photographerFilter" v-model="photographer"/>
                <place-autocomplete v-else v-model="place"/>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field v-model="query" :label="$t(`labels['identify']`)"/>
            </v-col>
            <v-col cols="12" md="4" align-self="center" :align="$vuetify.breakpoint.mdAndUp ? null : 'end'">
                <v-btn type="submit" outlined ripple color="blue-grey darken-1">
                    {{ $t("actions.apply") }}
                </v-btn>
            </v-col>
        </v-row>
    </form>
</template>
<script>
import PhotographerAutocomplete from "../photographer/Autocomplete"
import PlaceAutocomplete from "../place/Autocomplete"

export default {
    components: { PhotographerAutocomplete, PlaceAutocomplete },

    props: {
        photographerFilter: {
            type: Object,
            default: () => ({}),
        },

        photographers: {
            type: Boolean,
            default: true,
        },

        value: {
            type: Object,
            default: () => ({ query: "", photographer: "" }),
        },
    },

    data() {
        return {
            photographer: this.value.photographer || null,
            place: this.value.place || null,
            query: this.value.query || "",
        }
    },

    watch: {
        photographer(val) {
            const value = Object.assign({}, this.value, { photographer: val })
            this.$emit("input", value)
        },
        place(val) {
            const value = Object.assign({}, this.value, { place: val })
            this.$emit("input", value)
        },
        query(val) {
            const value = Object.assign({}, this.value, { query: val })
            this.$emit("input", value)
        },

        "value.query"(val) {
            this.query = val
        },

        "value.photographer"(val) {
            this.photographer = val
        },

        "value.place"(val) {
            this.place = val
        },
    },

    methods: {
        onSubmit() {
            this.$emit("submit")
        },
    },
}
</script>
<style lang="stylus">
    .photogroups-filter {
    }
</style>
