<template>
    <v-dialog v-model="visibleModal" persistent max-width="800px">
        <v-card :class="`modal-create-promo_${type}`">
            <div class="modal-create-promo__wrapper">
                <image-cropper
                    @init:start="onStartInitCropper()"
                    @inited="onInitedCropper()"
                    ref="cropper"
                    :src="options.image"
                    :viewport="getCropperViewport"
                    class="modal-create-promo__cropper">
                    <template slot="overlay">
                        <div class="modal-create-promo__stub" ref="stub"></div>
                    </template>
                </image-cropper>
            </div>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="error" outlined @click="onClickCancel()">{{ $t("actions.close") }}</v-btn>
                <v-btn v-show="visibleSubmit" outlined ripple color="blue-grey darken-1" @click="onClickSubmit()">
                    {{ $t('actions.continue') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalFormMixin from "./ModalFormMixin"
import ImageCropper from "../ImageCropper"
import LazyPromo from "../../services/LazyPromo"

const CARD_VIEWPORT = { width: 170, height: 189 }
const BADGE_VIEWPORT = { width: 300, height: 397 }

export default {
    components: { ImageCropper },
    mixins: [ModalFormMixin],

    props: {
        type: {
            type: String,
            default: "card",
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        getCropperViewport() {
            const type = this.type
            return type === "card" ? CARD_VIEWPORT : BADGE_VIEWPORT
        },
    },

    watch: {
        type() {
            this.createPlaceholder()
        },
        visibleModal(val) {
            if (!val) {
                this.clear()
            } else {
                this.reset()
            }
        },
    },

    data() {
        return {
            visibleSubmit: false,
        }
    },

    methods: {
        createPlaceholder() {
            LazyPromo()
                .then(Promo => this.type === "card" ? this.createCardPlaceholder(Promo) : this.createBadgePlaceholder(Promo))
                .then(card => card.toCanvas())
                .then(canvas => this.onRenderStub(canvas))
        },

        createCardPlaceholder(Promo) {
            return Promo.createCard({
                qr: { text: "XXXXXXXXX", url: "https://storemood.com" },
                name: this.options.name || "",
            }).draw()
        },

        createBadgePlaceholder(Promo) {
            return Promo.createBadge({
                qr: { text: "XXXXXXXXX", url: "https://storemood.com" },
                name: this.options.name || "",
            }).draw()
        },

        onRenderStub(canvas) {
            this.$refs.stub.innerHTML = ""
            this.$refs.stub.appendChild(canvas)
        },

        onClickCancel() {
            this.visibleModal = false
        },

        reset() {
            this.$refs.cropper && this.$refs.cropper.reset()
        },

        onClickSubmit() {
            this.$refs.cropper.getBlob()
                .then(blob => this.$emit("submit", blob))
        },

        onStartInitCropper() {
            this.visibleSubmit = false
        },

        onInitedCropper() {
            this.visibleSubmit = true
        },

        clear() {
        // Wait for modal animationend
            setTimeout(() => {
                this.visibleSubmit = false
                this.$refs.cropper && this.$refs.cropper.clear()
            }, 100)
        },
    },
}
</script>
<style lang="stylus">
    .modal-create-promo__cropper {
        width 100%
    }

    .modal-create-promo_card .modal-create-promo__cropper {
        height 400px
    }

    .modal-create-promo_badge .modal-create-promo__cropper {
        height 500px
    }

    .modal-create-promo__stub {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left 0;
        margin: auto;
    }

    .modal-create-promo_card .modal-create-promo__stub {
        width: 340px;
        height: 189px;
        left: 169px;

        canvas {
            width: 340px;
            height: 189px;
        }
    }

    .modal-create-promo_badge .modal-create-promo__stub {
        width: 300px;
        height: 397px;

        canvas {
            width: 300px;
            height: 397px;
        }
    }
</style>
