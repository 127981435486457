<template>
    <v-dialog v-model="visibleModal" persistent max-width="800px">
        <v-card>
            <place-form ref="form" v-model="model" @submit="onSubmit()" edit>
                <template slot="title">
                    {{ $t(`headers['edit place']`) }}
                </template>
            </place-form>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="error" outlined @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                <v-btn outlined ripple color="blue-grey darken-1" @click="onSubmit()">{{ $t("actions.save") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import ModalFormMixin from "./ModalFormMixin"
import CompoundInput from "../CompoundInput"
import CityAutocomplete from "../directories/CityAutocomplete"
import MapLocationControl from "../MapLocationControl"
import PlaceForm from "../PlaceForm"

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { CityAutocomplete, CompoundInput, MapLocationControl, PlaceForm },
    mixins: [ModalFormMixin],
    watch: {
        value(val) {
            this.model = val
        },

        model(val) {
            this.$emit("input", val)
        },

    },

    methods: {
        onClose() {
            this.close()
        },
    },
}
</script>
<style>
    .place-modal__map {
        height: 300px;
    }
</style>
