<template>
    <v-container fluid>
        <v-row justify="end" class="ma-1">
            <v-btn text small v-if="isAdmin" color="primary" @click="onClickChangeWallpaper()">
                {{ $t(`actions['change wallpapers']`) }}
            </v-btn>
            <v-btn @click="onClickEdit()" v-if="isAdmin" text small color="primary">{{ $t("actions.edit") }}</v-btn>
            <v-btn :href="`/places/${value.ID}` | webLink" target="_blank" text small color="primary">
                {{ $t(`actions['open site']`) }}
            </v-btn>
        </v-row>


        <div class="mx-1 my-2 d-flex flex-row">
            <v-list-item-avatar tile size="140">
                <div class="place-info__avatar">
                    <v-img
                        min-width="140"
                        max-width="140"
                        min-height="155"
                        max-height="155"
                        :src="value.Avatar.Cropped | getMedia('place')"/>
                    <div v-if="isAdmin" class="place-info__avatar-select d-flex align-center justify-center"
                         @click="onClickChangeAvatar()">
                        <v-icon size="48" color="white">mdi-pencil-box-multiple-outline</v-icon>
                    </div>
                </div>
            </v-list-item-avatar>

            <div>
                <div class="mb-4 headline">
                    {{ value.Name | getName }}
                </div>
                <div class="mb-2 place-info__sub-title">
                    <v-icon class="mr-1">mdi-map-marker</v-icon>
                    <span v-if="value.City && value.City.ID">{{ value.City.Name | getName }}</span>
                    <span v-else>{{ $t(`messages['no city']`) }}</span>
                </div>

                <v-menu
                    v-if="isAdmin"
                    v-model="visibleChangePubliclySearchable"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="indigo"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="my-2"
                        >
                            <v-icon left>
                                {{ menuIcon }}
                            </v-icon>
                            {{ $t(`labels.${menuText}`) }}
                        </v-btn>
                    </template>

                    <v-card class="pt-2 px-3">
                        <v-radio-group v-model="publiclySearchable">
                            <div>
                                <v-radio
                                    :value="true"
                                    class="mb-1"
                                >
                                    <span slot="label" class="place-info__radio-label">
                                        {{ $t('labels.photo search allowed') }}
                                    </span>
                                </v-radio>
                                <div class="ml-8 mb-2 place-info__sub-title place-info__radio-text">
                                    {{ $t('messages.photo search allowed') }}
                                </div>
                            </div>
                            <div>
                                <v-radio
                                    :value="false"
                                    class="mb-1"
                                >
                                    <span slot="label" class="place-info__radio-label">
                                        {{ $t('labels.photo search prohibited') }}
                                    </span>
                                </v-radio>
                                <div class="ml-8 mb-2 place-info__sub-title place-info__radio-text">
                                    {{ $t('messages.photo search prohibited') }}
                                </div>
                            </div>
                        </v-radio-group>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                text
                                :disabled="loadingPubliclySearchable"
                                @click="onCancelVisibleChangePubliclySearchable"
                            >
                                {{ $t('actions.cancel') }}
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                :loading="loadingPubliclySearchable"
                                :disabled="disableChangePubliclySearchable"
                                @click="onChangePubliclySearchable"
                            >
                                {{ $t('actions.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <div class="place-info__sub-title">
                    {{ value.Description | getDescription($t(`placeholders['no description']`)) }}
                </div>
            </div>
        </div>

        <place-form-modal :visible.sync="visibleEditModal" v-model="editModel" @submit="onSubmitPlace"/>

        <image-cropper-modal :visible.sync="visibleCropper" :viewport="getCropperViewport"
                             @submit="onSubmitCropper">
            <template slot="hint" v-if="!isChangeAvatar">
                {{ $t(`messages['avatar size']`) }}
            </template>
        </image-cropper-modal>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import PlaceFormModal from "../modal/PlaceFormModal"
import unobserve from "../../util/unobserve"
import ImageCropperModal from "../modal/ImageCropperModal"

export default {
    components: { PlaceFormModal, ImageCropperModal },

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            visibleEditModal: false,
            editModel: {},
            visibleCropper: false,
            cropperMode: "",
            visibleChangePubliclySearchable: false,
            publiclySearchable: false,
            loadingPubliclySearchable: false // блокируем кнопки и показываем лоадер на кнопке 'Сохранить'
        }
    },

    computed: {
        ...mapGetters("common", ["getUser"]),
        isAdmin() {
            const { AdminingPlaces, JS } = this.getUser
            return JS || AdminingPlaces.includes(this.value.ID)
        },

        isChangeAvatar() {
            return this.cropperMode === "avatar"
        },

        getPlaceAvatarViewport() {
            return { width: 170, height: 189, type: "rectangle" }
        },

        getPlaceWallpapperViewport() {
            return { width: 3384, height: 882, useRatio: true }
        },

        getCropperViewport() {
            return this.isChangeAvatar ? this.getPlaceAvatarViewport : this.getPlaceWallpapperViewport
        },

        menuText() {
            return this.value.Options.PubliclySearchable ? "photo search allowed" : "photo search prohibited"
        },

        menuIcon() {
            return this.value.Options.PubliclySearchable ? 'mdi-eye-outline' :  'mdi-eye-off-outline'
        },

        disableChangePubliclySearchable() {
            // Блокировка кнопки Сохранить PubliclySearchable
            if (this.loadingPubliclySearchable) {
                return true
            }
            return this.value.Options.PubliclySearchable === this.publiclySearchable
        }
    },

    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val) {
                this.publiclySearchable = val.Options.PubliclySearchable
            }
        }
    },

    methods: {
        ...mapActions("place", ["setLogo", "setWallpaper", "updatePubliclySearchable"]),
        onClickEdit() {
            this.visibleEditModal = true
            this.editModel = unobserve(this.value)
        },

        onClickChangeAvatar() {
            this.cropperMode = "avatar"
            this.visibleCropper = true
        },

        onClickChangeWallpaper() {
            this.cropperMode = "wallpaper"
            this.visibleCropper = true
        },

        onSubmitPlace() {
            this.visibleEditModal = false
            this.$emit("change", this.editModel)
        },

        loadingCall(callee, loadingText, successText, errorText) {
            this.$dialog.loading({ text: loadingText }).then(dialog => {
                callee()
                    .then(() => this.$emit("reload"))
                    .then(() => {
                        this.$dialog.message.success(successText)
                    })
                    .catch(() => {
                        this.$dialog.message.error(errorText)
                    })
                    .finally(() => dialog.close())
            })
        },

        onSubmitCropper(...args) {
            this.visibleCropper = false
            if (this.isChangeAvatar) {
                this.onSubmitPlaceAvatar(...args)
            } else {
                this.onSubmitPlaceWallpaper(...args)
            }
        },

        onSubmitPlaceAvatar(params) {
            const id = this.value.ID
            this.loadingCall(
                () => this.setLogo(Object.assign({}, params, { placeId: id })),
                this.$t(`messages['photo uploads']`),
                this.$t(`messages['place logo success']`),
                this.$t(`messages['picture upload error']`),
            )
        },

        onSubmitPlaceWallpaper(params) {
            const id = this.value.ID
            this.loadingCall(
                () => this.setWallpaper(Object.assign({}, params, { placeId: id })),
                this.$t(`messages['photo uploads']`),
                this.$t(`messages['place wall success']`),
                this.$t(`messages['picture upload error']`),
            )
        },

        onChangePubliclySearchable() {
            this.loadingPubliclySearchable = true
            this.updatePubliclySearchable({
                id: this.value.ID,
                publiclySearchable: this.publiclySearchable
            })
                .then(() => {
                    this.visibleChangePubliclySearchable = false
                    this.$emit('reload')
                })
                .catch((error) => {
                    console.error('onChangePubliclySearchable', error)
                })
                .finally(() => {
                    this.loadingPubliclySearchable = false
                })
        },

        onCancelVisibleChangePubliclySearchable() {
            this.visibleChangePubliclySearchable = false
            this.publiclySearchable = this.value.Options.PubliclySearchable
        }
    },
}
</script>

<style lang="stylus">
.v-card .v-offset .v-card--material__header.v-card {
  padding 0
}

.place-info__avatar {
  position relative

  .place-info__avatar-select {
    position absolute
    width 100%
    height 97%
    background rgba(0, 0, 0, 0.5)
    left 0
    top 0
    /*border-radius 100%*/
    opacity 0
    transition opacity linear 0.2s
  }

  .place-info__avatar-select:hover {
    cursor pointer
  }
}

.place-info__avatar:hover .place-info__avatar-select {
  opacity 1
}

.place-info__sub-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.place-info__radio-label {
  color: #000000;
  font-size: 16px;
}

.place-info__radio-text {
  max-width: 350px;
}
</style>
