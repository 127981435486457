<template>
    <v-container fluid>
        <div class="mt-6">
            <photogroups-filter
                @submit="onSubmitFilter()"
                v-model="filter"
                :photographers="!isPhotographerScope"
                :photographer-filter="{PlaceID: placeId}"/>

            <div v-if="loading" class="d-flex justify-center mt-8 mb-8 flex-column align-center">
                <v-progress-circular
                    :size="50"
                    color="blue-grey darken-4"
                    indeterminate/>
                <p class="headline mt-4">{{ $t(`messages['loading data']`) }}</p>
            </div>

            <div v-show="isEmpty && !appendLoading && !loading" class="text-center mb-4 py-3">
                <p class="subtitle-1">{{ $t(`messages['no sessions']`) }}</p>
            </div>

            <div v-if="!isEmpty || !loading">
                <photo-actions v-if="!photographerScope" sessions-scope :selected-photogroups.sync="selected"
                               :selected-photos="getPhotoreportPhotos" :photogroups="photoreports"
                               :place-id="placeId" @reload="loadPhotoreports()"/>
                <grid-photoreports v-model="photoreports"
                                   :fetch-rows="2"
                                   :selected.sync="selected"
                                   @fetch:bottom="appendPhotoreports()" :readonly="photographerScope">
                    <template slot="attributes" slot-scope="props">
                        <div class="photoreport-control__grid-row_attribute">
                            {{props.photoreport.self.PhotoIDs.length}} {{ $tc('plural.photos', props.photoreport.self.PhotoIDs.length) }}
                        </div>
                        <div class="photoreport-control__grid-row_attribute"
                             v-if="props.photoreport.self.Features.ClientName">
                            {{ $t("rudiments.client", [props.photoreport.self.Features.ClientName]) }}
                        </div>
                    </template>
                </grid-photoreports>
                <div v-if="appendLoading" class="d-flex justify-center mt-6 mb-6">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate/>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import GridPhotoreports from "storemoodify/lib/GridPhotoreports"
import PhotogroupsFilter from "../photogroups/Filter"
import * as Adapters from "../../util/adapters"
import PhotoActions from "./PhotoActions"
import unobserve from "../../util/unobserve"

GridPhotoreports.urlBuilder = (photoreport) => `/session/${photoreport.id}`

// eslint-disable-next-line no-unused-vars
const PHOTOREPORTS_LIMIT = 10

export default {
    name: "PhotoreportsControl",
    // eslint-disable-next-line vue/no-unused-components
    components: { GridPhotoreports, PhotogroupsFilter, PhotoActions },
    props: {
        placeId: {
            type: String,
            default: "",
        },
        photographerScope: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            photoreports: [],
            selected: [],
            canLoadNext: true,
            loading: false,
            filter: {},
            submittedFilter: {},
            appendLoading: false,
        }
    },

    created() {
        this.appendPhotoreports()
    },

    computed: {
        ...mapGetters("common", ["getUser"]),
        isEmpty() {
            return this.photoreports.length === 0
        },

        getPhotoreportPhotos() {
        // eslint-disable-next-line no-unused-vars
            return this.selected.reduce((res, pr) => {
                const report = this.photoreports.find(r => r.id === pr)
                res.push(...report.self.PhotoIDs)
                return res
            }, [])
        },

        isPhotographerOnly() {
            return !this.getUser.JS && !this.getUser.AdminingPlaces.includes(this.placeId)
        },

        isPhotographerScope() {
            return this.placeId === "" && this.photographerScope
        },

        getPhotographerId() {
            return this.getUser.ID
        },

        getOffset() {
            return this.photoreports.length
        },

        getFilter() {
            const { photographer, query, place } = this.submittedFilter
            const result = { Query: query || "" }

            if (place) {
                result.PlaceID = place.ID
            }

            if (photographer) {
                result.CreatorID = photographer.ID
            }

            if (this.isPhotographerOnly) {
                result.CreatorID = this.getPhotographerId
            }

            if (this.isPhotographerScope) {
                result.CreatorID = this.getPhotographerId
            } else {
                result.PlaceID = this.placeId
            }

            return result
        },
    },

    methods: {
        ...mapActions({
            findPhotoreports: "photoreport/search",
        }),

        appendPhotoreports() {
            if (this.appendLoading || !this.canLoadNext) {
                return
            }
            this.appendLoading = true
            this.fetchPhotoreports()
                .then(res => this.photoreports.push(...res))
                .finally(() => this.appendLoading = false)
        },

        fetchPhotoreports() {
            const params = {
                Limit: PHOTOREPORTS_LIMIT,
                Offset: this.getOffset,
                ...this.getFilter,
            }

            return this.findPhotoreports({ params })
                .then(res => {
                    this.canLoadNext = PHOTOREPORTS_LIMIT <= res.length
                    return Adapters.photoreports(res)
                })
        },

        loadPhotoreports() {
            this.loading = true
            this.photoreports = []
            this.fetchPhotoreports()
                .then(res => {
                    this.photoreports = res
                })
                .finally(() => {
                    this.loading = false
                })
        },

        onSubmitFilter() {
            this.submittedFilter = unobserve(this.filter)
            this.loadPhotoreports()
        },

    },
}
</script>

<style lang="stylus">
    .photoreport-control__grid-row_attribute {
        text-decoration none !important
    }
</style>
