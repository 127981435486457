<template>
    <form @submit.prevent="onSubmit()">
        <v-text-field
            :error="hasError('phone')"
            :error-messages="firstError('phone')"
            prefix="+"
            type="tel"
            v-model="model.phone"
            color="purple darken-2"
            :label="$t(`labels['phone number']`)"/>

        <v-text-field
            :error="hasError('email')"
            :error-messages="firstError('email')"
            v-model="model.email"
            color="purple darken-2"
            type="email"
            :label="$t(`labels['clients email']`)"/>

        <v-text-field
            :error="hasError('firstName')"
            :error-messages="firstError('firstName')"
            v-model="model.firstName"
            color="purple darken-2"
            :label="$t(`labels['clients name']`)"/>

        <v-text-field
            :error="hasError('lastName')"
            :error-messages="firstError('lastName')"
            v-model="model.lastName"
            color="purple darken-2"
            :label="$t(`labels['clients lastname']`)"/>

        <date-time-picker
            :error="hasError('photoTime')"
            :error-messages="firstError('photoTime')"
            v-model="model.photoTime"
            :label="$t(`labels['shoot date']`)"/>

        <date-time-picker
            :error="hasError('worksheetTime')"
            :error-messages="firstError('worksheetTime')"
            v-model="model.worksheetTime"
            :label="$t(`labels['worksheet date']`)"/>
    </form>
</template>
<script>
import Form from "storemoodify/lib/Form"
import DateTimePicker from "./DateTimePicker"

export default {
    components: { DateTimePicker },
    mixins: [Form],
    mounted() {
        this.model = this.defaultValue()
    },
    methods: {
        defaultValue() {
            return {
                firstName: "",
                lastName: "",
                phone: "7",
                email: "",
                photoTime: new Date(),
                worksheetTime: new Date(),
            }
        },

        getConstraints() {
            return {
                email: {
                    presence: {
                        message: this.$t(`errors['field required']`)
                    },
                },
                phone: {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                    length: {
                        minimum: 3,
                        tooShort: this.$t(`errors['field required']`),
                    },
                },
                firstName: {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                },
                photoTime: {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                },
                worksheetTime: {
                    presence: {
                        message: this.$t(`errors['field required']`),
                        allowEmpty: false,
                    },
                },
            }
        },
    },
}
</script>
<style lang="stylus">

</style>
