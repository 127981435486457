<template>
    <v-dialog v-model="visibleModal" persistent max-width="600px">
        <v-card>
            <v-form ref="form" v-model="isValid" @submit.prevent="onSubmit()">
                <v-card-title class="headline">{{ $t(`actions['edit terms']`) }}</v-card-title>
                <v-card-subtitle class="pt-2 ml-2" v-if="model.name">
                    {{model.name}}
                </v-card-subtitle>
                <v-card-text>
                    <v-text-field class="employee-share__field" :rules="rules" type="number" min="0" max="100"
                                  v-model="model.share" :label="$t(`tables.share`)"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue-grey darken-1" text @click="onClose()">{{ $t("actions.cancel") }}</v-btn>
                    <v-btn color="green darken-2" text type="submit">{{ $t("actions.submit") }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import ModalFormMixin from "../modal/ModalFormMixin"

export default {
    mixins: [ModalFormMixin],
    data() {
        return {
            rules: [
                v => v > 0 || this.$t(`errors['not less']`, [0]),
                v => v < 100 || this.$t(`errors['not more']`, [100]),
                v => !!v || this.$t(`errors['field required']`),
            ],
        }
    },
    methods: {
        onClose() {
            this.close()
        },
    },
}
</script>

<style lang="stylus">
    .employee-share__field {
        -moz-appearance: textfield;
    }

    .employee-share__field::-webkit-outer-spin-button,
    .employee-share__field::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
</style>
