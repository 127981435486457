<template>
    <div>
        <autocomplete v-model="model"
                      :label="$t(`labels['choose city']`)"
                      :rules="rules"
                      :items="cities"
                      :multiple="multiple"
                      :loading="isLoading"
                      :to-option="cityToOption"
                      no-filter
                      @change="onChangeQuery"/>
    </div>
</template>
<script>
import { mapActions } from "vuex"
import $get from "storemoodify/util/get"
import * as Format from "@/util/format"
import unobserve from "@/util/unobserve"
import Autocomplete from "../Autocomplete"


const cityOrNull = (value) => {
    const cityId = $get(value, "ID", null) || null

    return cityId ? unobserve(value) : null
}

export default {
    components: { Autocomplete },
    props: {
        value: [Array, Object],
        multiple: Boolean,
        rules: Array,
    },
    data() {
        return {
            model: cityOrNull(this.value),
            cities: [],
            isLoading: false,
            requestId: 0,
        }
    },
    watch: {
        model() {
            this.$emit("input", this.model)
        },
        value() {
            const cityId = $get(this.value, "ID", null) || null
            this.model = cityId ? this.value : null
        },
    },
    methods: {
        ...mapActions("directory", ["findCity"]),
        cityToOption(city) {
            return { id: city.ID, name: Format.getName(city.Name) }
        },

        onChangeQuery(query) {
            this.isLoading = true
            this.requestId++
            const { requestId } = this
            this.findCity(query).then(cities => {
                // eslint-disable-next-line no-console
                if (this.requestId === requestId) {
                    this.isLoading = false
                    this.cities = cities
                }
            })
        },
    },
}
</script>
