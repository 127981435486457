<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6" md="3" lg="3" v-for="(tile, $index) in getTiles" :key="$index">
                <v-card max-width="500" class="mx-auto" @click="tile.action">
                    <v-list-item>
                        <v-list-item-avatar tile size="60">
                            <v-icon size="60">{{tile.icon}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle text-wrap">{{tile.text}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <scanner-modal
            ref="scanner"
            @capture="onCapturePromo"
            :manual="!waitForCheck"
            :validator="getScannerValidator"
            :visible.sync="visibleRecognizePromo">
            <template slot="title">
                <span v-show="!waitForCheck">{{ $t(`headers['promo activation']`) }}</span>
                <span v-show="waitForCheck">{{ $t(`messages['bring check']`) }}</span>
            </template>
        </scanner-modal>

        <modal-create-promo
            :options="promoOptions"
            :type="currentPromoType"
            :visible.sync="visiblePromoModal"
            @submit="onSubmitPromo"/>

        <worksheet-form-modal ref="worksheet" @submit="onSubmitWorksheet()" v-model="worksheet"
                              :visible.sync="visibleWorksheetModal"/>
    </v-container>
</template>

<script>
import { ResourceError } from "storemood-connection"
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import ScannerModal from "../modal/ScannerModal"
import ModalCreatePromo from "../modal/ModalCreatePromo"
import WorksheetFormModal from "../modal/WorksheetFormModal"
import LazyPromo from "../../services/LazyPromo"
import Format from "../../util/format"

const transformDiscountCodes = (codes, date, placeName) => codes.map((code) => ({
    date,
    placeName,
    qr: { url: code.Url, text: code.Code },
    discount: Format.currency(code.Discount),
}))

export default {
    components: { ScannerModal, ModalCreatePromo, WorksheetFormModal },

    props: {
        place: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            visibleRecognizePromo: false,
            waitForCheck: false,
            visiblePromoModal: false,
            visibleWorksheetModal: false,
            currentPromoType: null,
            promoOptions: {},
            worksheet: {},
        }
    },

    computed: {
        ...mapGetters("common", ["getUser"]),
        placeName() {
            return Format.getName(this.place.Name)
        },

        getScannerValidator() {
            return this.waitForCheck ? "" : "promo"
        },

        isAdmin() {
            return this.place.Admins.includes(this.getUser.ID) || this.getUser.JS
        },

        isSeller() {
            return this.place.Sellers.includes(this.getUser.ID)
        },

        isPhotographer() {
            return this.place.Photographers.includes(this.getUser.ID)
        },

        getTiles() {
            const { isAdmin, isSeller, isPhotographer } = this

            const cards = [
                (isAdmin || isSeller) ? {
                    text: this.$t(`labels['create worksheet']`),
                    icon: "mdi-account-plus",
                    action: () => this.onClickCreateWorksheet(),
                } : null,
                isAdmin ? {
                    text: this.$t(`labels['generate codes']`),
                    icon: "mdi-cash-plus",
                    action: () => this.onClickCreateCodes(),
                } : null,
                isAdmin ? {
                    text: this.$t(`labels['print day code']`),
                    icon: "mdi-qrcode",
                    action: () => this.onClickPrintDailyCode(),
                } : null,
                isAdmin ? {
                    text: this.$t(`labels['activate promo']`),
                    icon: "mdi-toggle-switch-outline",
                    action: () => this.onClickActivatePromo(),
                } : null,
                (isPhotographer || isAdmin) ? {
                    text: this.$t(`labels['print codes']`),
                    icon: "mdi-smart-card-outline",
                    action: () => this.onClickCreateCard(),
                } : null,
                (isPhotographer || isAdmin) ? {
                    text: this.$t(`labels['print badge']`),
                    icon: "mdi-badge-account-horizontal-outline",
                    action: () => this.onClickCreateBadge(),
                } : null,
            ]

            return cards.filter(t => t !== null)
        },
    },

    mounted() {
        LazyPromo()
    },

    methods: {
        ...mapActions("promo", ["createPromoCodes", "createDailyCode", "activateCode", "createPlaceCardCodes", "createPlaceBadgeCode"]),
        ...mapActions("place", ["addWorksheet"]),

        // Discount promo
        onClickCreateCodes() {
            LazyPromo()
                .then(res => res.constructor.discountsPerPage())
                .then(discountsPerPage => this.requestUserInputCodes(discountsPerPage))
                .then(inputPages => {
                    if (inputPages === false) {
                        return
                    }

                    const pages = parseInt(inputPages, 10) || 1

                    this.$dialog.loading({ text: this.$t(`messages['print progress']`) }).then(dialog => {
                        this.printPromoCodes(pages).finally(() => dialog.close())
                    })
                })
        },

        printPromoCodes(pages = 1) {
            const ts = dayjs().endOf("day").unix()
            const renderDate = dayjs().format("DD.MM.YYYY")

            return LazyPromo().then(Promo => {
                const codesPerPage = Promo.constructor.discountsPerPage()
                return this.createPromoCodes({ count: codesPerPage * pages, placeId: this.place.ID, date: ts })
                    .then(codes => transformDiscountCodes(codes, renderDate, this.placeName))
                    .then(codes => ({ codes, Promo }))
            })
                .then(({ codes, Promo }) => Promo.createA4Discount(codes).draw())
                .then(a4 => a4.print(this.$t(`messages['promo of']`, [this.placeName, renderDate])))
        },

        requestUserInputCodes(discountsPerPage) {
            return this.$dialog.prompt({
                title: this.$t(`messages['codes per page']`, [discountsPerPage]),
                text: this.$t(`labels['set pages']`),
                width: 580,
            })
        },

        // Day QR
        onClickPrintDailyCode() {
            this.$dialog.datepicker({ title: this.$t(`labels['choose event date']`) })
                .then(res => {
                    if (!res) {
                        return
                    }

                    this.$dialog.loading({ text: this.$t(`messages['form progress']`) }).then(dialog => {
                        this.printDailyCode(res).finally(() => dialog.close())
                    })
                })
        },

        printDailyCode(dateStr) {
            const date = dayjs(dateStr, "YYYY.MM.DD")
            const renderDate = date.format("DD.MM.YYYY")

            return Promise.all([
                this.createDailyCode({ placeId: this.place.ID, date: date.unix() }),
                LazyPromo(),
            ]).then(([code, Promo]) => Promo.createDailyCode({ url: code.Url, date: renderDate, name: this.placeName }))
                .then(dailyCode => dailyCode.draw())
                .then(dailyCode => dailyCode.print(this.$t(`messages['daily code']`, [this.placeName, renderDate])))
        },


        // Activate promo code
        onClickActivatePromo() {
            this.waitForCheck = false
            this.visibleRecognizePromo = true
        },

        onCapturePromo(code) {
            if (this.waitForCheck) {
                this.activatePromo(this.$activateCode, code)
            } else {
                this.$refs.scanner.triggerSuccess()
                this.$activateCode = code
                this.waitForCheck = true
            }
        },

        activatePromo(code, activation) {
            this.visibleRecognizePromo = false

            this.$dialog.loading({ text: this.$t(`messages['activation progress']`) }).then(dialog => {
                this.activateCode({ code, activation })
                    .then(() => this.onDoneActivateCode())
                    .catch((e) => this.onErrorActivateCode(e))
                    .finally(() => dialog.close())
            })
        },

        onDoneActivateCode() {
            this.$dialog.message.success(this.$t(`messages['promo activate success']`))
        },

        onErrorActivateCode(error) {
            let message = null
            new ResourceError(error)
                .ifCode("already activated", () => message = this.$t(`errors['code already activated']`))
                .ifCode("promocode by non-admin user", () => message = this.$t(`errors['non admin']`))
                .ifCode("activation code not valid", () => message = this.$t(`errors['wrong check']`))
                .else(() => message = this.$t(`errors['activation error']`))

            this.$dialog.error({ text: message, title: this.$t(`errors['error']`) })
        },

        // Create photographer CARD
        onClickCreateCard() {
            this.promoOptions.image = Format.getMedia(this.place.Avatar.Original, "default")
            this.promoOptions.name = this.placeName
            this.currentPromoType = "card"
            this.visiblePromoModal = true
        },

        adminBadge() {
            this.$dialog.selectUser({
                title: this.$t(`labels['choose photographer']`),
                params: { PlaceID: this.place.ID },
            }).then(user => {
                if (user) {
                    this.createBadge(user)
                }
            })
        },

        userBadge() {
            const user = this.getUser
            this.createBadge(user)
        },

        createBadge(user) {
            this.promoOptions.image = Format.getMedia(user.Avatar.Original, "default")
            this.promoOptions.name = Format.getUserName(user.Name)
            this.currentPromoType = "badge"
            this.visiblePromoModal = true
        },

        // Create badge
        onClickCreateBadge() {
            if (this.isAdmin) {
                this.adminBadge()
            } else {
                this.userBadge()
            }
        },

        // eslint-disable-next-line no-unused-vars
        onSubmitPromo({ cropped, original }) {
            this.visiblePromoModal = false
            if (this.currentPromoType === "card") {
                this.onSubmitPromoCard(cropped)
            } else {
                this.onSubmitPromoBadge(cropped)
            }
        },

        onSubmitPromoCard(blob) {
            LazyPromo().then(Promo => {
                return this.$dialog.prompt({
                    title: this.$t(`messages['cards per page']`, [Promo.constructor.cardsPerPage()]),
                    text: this.$t(`labels['set pages']`),
                    width: 580,
                })
            }).then(inputPages => {
                if (inputPages === false) {
                    return
                }

                const pages = parseInt(inputPages, 10) || 1
                this.$dialog.loading({ text: this.$t(`messages['cards progress']`) }).then(dialog => {
                    this.renderCards(blob, pages)
                        .finally(() => dialog.close())
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        onSubmitPromoBadge(blob) {
            this.$dialog.loading({ text: this.$t(`messages['badge progress']`) }).then(dialog => {
                this.renderBadge(blob)
                    .finally(() => dialog.close())
            })
        },

        // eslint-disable-next-line no-unused-vars
        renderCards(image, pages) {
            return LazyPromo().then(Promo => {
                const perPage = Promo.constructor.cardsPerPage()
                return this.createPlaceCardCodes({ placeId: this.place.ID, count: perPage * pages })
            }).then(codes => {
                return codes.map(({ Url, Code }) => ({
                    name: this.placeName,
                    picture: image,
                    qr: { url: Url, text: Code },
                }))
            }).then(cards => LazyPromo().then(Promo => Promo.createA4Cards(cards).draw()))
                .then(a4 => a4.print())
        },

        renderBadge(image) {
            return Promise.all([
                LazyPromo(),
                this.createPlaceBadgeCode({ placeId: this.place.ID }),
            ]).then(([Promo, url]) => {
                return Promo.createBadge({
                    name: this.promoOptions.name,
                    background: image,
                    qr: { url: url },
                }).draw()
            }).then(badge => badge.print())
        },

        onClickCreateWorksheet() {
            this.visibleWorksheetModal = true
        },

        onSubmitWorksheet() {
            this.$dialog.loading({ text: this.$t(`messages['notifications progress']`) }).then(dialog => {
                this.addWorksheet({ ...this.worksheet, placeId: this.place.ID })
                    .then(() => this.onSuccessCreateWorksheet())
                    .catch((e) => this.onErrorCreateWorksheet(e))
                    .finally(() => dialog.close())
            })
        },

        onSuccessCreateWorksheet() {
            this.$refs.worksheet.clear()
            this.$dialog.message.success(this.$t(`messages['worksheet success']`))
        },

        onErrorCreateWorksheet() {
            this.$dialog.message.error(this.$t(`errors['worksheet create error']`))
        },
    },
}
</script>
