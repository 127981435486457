<template>
    <v-form class="place-form" ref="form" v-model="isValid" @submit.prevent="onSubmit()">
        <v-card-title>
            <span class="headline">
                <slot name="title">
                    <template slot="title"></template>
                </slot>
            </span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <header class="body-1 mb-2">{{ $t(`labels['choose place city']`) }}</header>
                <city-autocomplete name="city" v-model="model.City" :rules="requiredField"/>
            </v-container>

            <!--            <v-container v-if="!edit">-->
            <!--                <header class="body-1 mb-2">Выберите LLID</header>-->
            <!--                <user-autocomplete name="llid" v-model="model.LLID" :rules="requiredField"/>-->
            <!--            </v-container>-->

            <v-container>
                <header class="body-1 mb-2">{{ $t(`labels['place name']`) }}</header>
                <compound-input name="name" v-model="model.Name" :rules="requiredName" ref="name"/>
            </v-container>

            <v-container>
                <header class="body-1 mb-2">{{ $t(`labels['place description']`) }}</header>
                <compound-input name="description" v-model="model.Description" :rules="requiredName" multiline
                                ref="name"/>
            </v-container>

            <header class="mb-2">
                {{ $t(`labels['coordinates']`) }}
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>mdi-help-circle-outline</v-icon>
                    </template>
                    <span>{{ $t(`labels['place map']`) }}</span>
                </v-tooltip>
            </header>

            <map-location-control
                ref="map"
                class="place-form__map"
                v-model="model.Location"
                :rules="requiredLocation"
                as-model/>
        </v-card-text>
    </v-form>
</template>
<script>
import CompoundInput from "./CompoundInput"
import CityAutocomplete from "./directories/CityAutocomplete"
import MapLocationControl from "./MapLocationControl"
import UserAutocomplete from "./UserAutocomplete"

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { CityAutocomplete, CompoundInput, MapLocationControl, UserAutocomplete },
    props: {
        value: Object,
        edit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            model: this.value || {},
            isValid: true,
        }
    },

    computed: {
        requiredLocation() {
            return [v => !v || !v.Lat || !v.Lon ? this.$t(`errors.coordinates`) : true]
        },
        requiredField() {
            return [v => !!v || this.$t(`errors['field required']`)]
        },
        requiredName() {
            return [
                (v, key) => {
                    if (key !== "RU") {
                        return true
                    }

                    return !!(v || "").trim() || this.$t(`errors['field required']`)
                },
            ]
        },
    },

    watch: {
        "model.City.ID"() {
            this.onChangeCity()
        },
    },

    methods: {
        onChangeCity() {
            const city = this.model.City || {}
            if (city.ID && city.Location && this.$refs.map) {
                const { Lat, Lon } = city.Location
                this.$refs.map.focusTo(Lat, Lon)
            }
        },

        onSubmit() {
            this.$emit("submit")
        },

        validate() {
            return this.$refs.form.validate()
        },
    },
}
</script>
<style>
    .place-form__map {
        height: 300px;
    }
</style>
