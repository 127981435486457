<template>
    <div>
        <portal to="toolbar">
            <v-tabs
                center-active
                class="gray-tabs toolbar-tabs" slider-color="orange" background-color="blue-grey lighten-5"
                v-model="tab">

                <v-tab to="#main">
                    {{ $t(`tabs['info']`) }}
                </v-tab>

                <!--                <v-tab to="#reports" disabled v-if="isAdmin">-->
                <!--                    Отчеты-->
                <!--                </v-tab>-->

                <v-tab v-if="isAdmin" to="#employees">
                    {{ $t(`tabs['employees']`) }}
                </v-tab>

                <v-tab v-if="isAdmin" to="#stat">
                    {{ $t(`tabs['photographers stats']`) }}
                </v-tab>

                <v-tab to="#promo">
                    {{ $t(`tabs['promo']`) }}
                </v-tab>

                <v-tab disabled v-if="isAdmin" to="#summary">
                    {{ $t(`tabs['summary stats']`) }}
                </v-tab>

                <v-tab to="#photos">
                    {{ $t(`tabs['photos control']`) }}
                </v-tab>

                <v-tab disabled to="#reports" v-if="isAdmin">
                    {{ $t(`tabs['accounting']`) }}
                </v-tab>

                <v-tab disabled v-if="isAdmin">
                    {{ $t(`tabs['control']`) }}
                </v-tab>
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">
            <v-tab-item id="main" :transition="false" :reverse-transition="false" class="transparent-body">

                <div v-if="reloading" class="py-10 align-center justify-center d-flex">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate/>
                </div>

                <place-info
                    v-show="!reloading"
                    @reload="reload()"
                    @change="onChangePlace"
                    v-model="getPlace"/>

                <div v-if="getPageData.dashboard" class="px-6 mt-6 pb-3">
                    <p class="headline">{{ $t(`headers['indicators today']`) }}</p>
                    <daily-dashboard v-model="getPageData.dashboard"/>
                </div>
            </v-tab-item>

            <v-tab-item id="reports" :transition="false" :reverse-transition="false" v-if="isAdmin"
                        class="transparent-body">
                <reports-controller :place="getPlace"/>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false" id="employees">
                <employees-control :place-id="getPlace.ID" />
            </v-tab-item>

            <v-tab-item id="stat" :transition="false" :reverse-transition="false" v-if="isAdmin"
                        class="transparent-body">
                <photographer-table :params="getPhotographerParams" :url="getPhotographerUrl"/>
            </v-tab-item>

            <v-tab-item id="promo" :transition="false" :reverse-transition="false"
                        class="transparent-body">
                <v-container fluid v-if="isAdmin">
                    <v-row class="pl-4">
                        <v-switch @change="onChangeAutoActivate" v-model="autoActivatePromo">
                            <template v-slot:label>{{ $t(`actions['activate print']`) }}</template>
                        </v-switch>
                    </v-row>
                </v-container>
                <place-promo :place="getPlace"/>
            </v-tab-item>

            <v-tab-item id="summary" :transition="false" :reverse-transition="false" v-if="isAdmin"
                        class="transparent-body">
                {{ $t(`tabs['summary stats']`) }}
            </v-tab-item>

            <v-tab-item id="photos" :transition="false" :reverse-transition="false" class="transparent-body">
                <photos-control :place-id="getPlace.ID"/>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false" v-if="isAdmin" class="transparent-body">
                {{ $t(`tabs['control']`) }}
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { ResourceError } from "storemood-connection"
import * as Format from "../util/format"
import EmployeesControl from "../components/employees/EmployeesControl"
import PhotographerTable from "../components/photographer/TableController"
import PlacePromo from "../components/place/PlacePromo"
import PlaceInfo from "../components/place/PlaceInfo"
import PhotosControl from "../components/photos/PhotosControl"
import DailyDashboard from "../components/DailyDashboard"
import ReportsController from "../components/reports/ReportsController"
import ImageCropper from "../components/ImageCropper"

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        ImageCropper,
        DailyDashboard,
        PhotographerTable,
        PlacePromo,
        PlaceInfo,
        EmployeesControl,
        PhotosControl,
        ReportsController,
    },

    fetch({ store, redirect, params }) {
        const user = store.getters["common/getUser"]
        const isJesus = store.getters["common/isJesus"]
        const adminigPlaces = user.AdminingPlaces || []
        const employmentPlaces = user.EmploymentPlaces || []
        const hasAccess = adminigPlaces.includes(params.id) || employmentPlaces.includes(params.id) || isJesus

        if (!hasAccess) {
            return redirect("/forbidden")
        }

        return store.dispatch("place/fetchPage", params.id)
    },

    head() {
        return {
            title: this.$t('titles.place', [this.getPlaceName]),
            toolbar: this.getPlaceName,
        }
    },

    data() {
        return {
            tab: this.$route.hash || "main",
            reloading: false,
            placePhotographers: [],
            autoActivatePromo: false,
        }
    },

    mounted() {
        this.storePlacePhotographers()
    },

    watch: {
        getPlace() {
            this.autoActivatePromo = this.getPlace.AutoActivatePromo
        },
    },

    created() {
        this.autoActivatePromo = this.getPlace.AutoActivatePromo
    },

    computed: {
        ...mapGetters("place", ["getPageData"]),
        ...mapGetters("common", ["getUser", "isAuthorized"]),
        getPlace() {
            return this.getPageData.place
        },

        getPlaceName() {
            return Format.getName(this.getPlace.Name)
        },

        isAdmin() {
            return this.getPlace.Admins.includes(this.getUser.ID) || this.getUser.JS
        },

        getPhotographerParams() {
            return { placeId: this.getPlace.ID }
        },
    },

    methods: {
        ...mapActions("place", ["updateName", "updateDescription", "updateCity", "updateLocation", "fetchPage"]),
        ...mapActions({ getUsers: "user/getByIds" }),
        ...mapActions("place", ["updateName", "updateDescription", "updateCity", "updateLocation", "fetchPage", "setAutoGeneratePromo"]),
        getPhotographerUrl(photographer) {
            return `/photographers/${photographer.ID}?place=${this.getPlace.ID}`
        },

        storePlacePhotographers() {
            this.getUsers(this.getPlace.Photographers)
                .then(photographers => {
                    this.placePhotographers = photographers
                })
        },

        onChangePlace(value) {
            this.reloading = true
            const id = this.getPlace.ID
            const { Description, Name, City, Location } = value

            Promise.all([
                this.updateDescription({ description: Description, id }),
                this.updateName({ name: Name, id }),
                this.updateLocation({ location: Location, id }),
                City ? this.updateCity({ city: City.ID, id }) : null,
            ]).finally(() => this.reload())
        },

        reload() {
            this.reloading = true
            this.fetchPage(this.getPlace.ID)
                .finally(() => this.reloading = false)
        },

        onChangeAutoActivate(value) {
            this.$dialog.loading({ text: this.$t(`messages['applying settings']`) }).then((dialog) => {
                this.setAutoGeneratePromo({ placeId: this.getPlace.ID, value })
                    .then(() => this.onChangedAutoActivate())
                    .catch((e) => this.onErrorChangedAutoActivate(e))
                    .finally(() => dialog.close())
            })
        },

        onChangedAutoActivate() {
            return this.fetchPage(this.getPlace.ID).then(() => {
                this.$dialog.message.success(this.$t(`messages['settings applied']`))
            })
        },

        onErrorChangedAutoActivate(e) {
            this.autoActivatePromo = this.getPlace.AutoActivatePromo

            let message = null
            new ResourceError(e)
                .ifCode("execute admin-only operation by non-admin user", () => message = this.$t(`errors['non admin']`))
                .else(() => message = this.$t(`errors['try later']`))

            this.$dialog.error({ text: message, title: this.$t(`errors['error']`) })
        },
    },
}
</script>
<style>
    .transparent-body {
        background: transparent
    }
</style>
