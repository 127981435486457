<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th width="200" class="text-left"></th>
                        <th v-for="employee in employees" :key="employee.ID" class="text-left">
                            {{employee.Name | getName }}<span v-show="employee.ShiftRole">({{ employee.ShiftRole | roleName }})</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in products" :key="product.ID">
                        <td class="text-left">{{product.Name}}</td>
                        <td v-for="employee in employees" :key="employee.ID" class="text-left">
                            <report-product-form :disabled="disabled" v-model="model[employee.ID][product.ID]"/>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import ReportProductForm from "./ReportProductForm"

const defaultValue = () => ({ total: "", count: "" })
const transformSale = ({ total, count }) => {
    return {
        total: parseFloat(total) || "",
        count: parseFloat(count) || "",
    }
}

export default {
    components: { ReportProductForm },
    props: {
        employees: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            model: {},
        }
    },

    created() {
        this.$watch("employees", this.updateModel, { immediate: true })
        this.$watch("products", this.updateModel, { immediate: true })
        this.$watch("model", this.commit, { deep: true })
        this.$watch("value", this.onChangeValue, { deep: true })
    },

    methods: {
        updateModel() {
            const { employees, products, model } = this
            this.model = employees.reduce((res, user) => {
                const currentVal = model[user.ID] || {}
                const value = res[user.ID] = {}
                products.forEach(product => {
                    value[product.ID] = transformSale(currentVal[product.ID] || defaultValue())
                })
                return res
            }, {})
        },

        commit() {
            this.$nextTick().then(() => this.$emit("input", this.getValue()))
        },

        getValue() {
            const employees = Object.keys(this.model)
            return employees.reduce((res, userId) => {
                const sales = this.model[userId]
                const productIds = Object.keys(sales)
                return res.concat(productIds.map(productId => {
                    const { total, count } = sales[productId]
                    return {
                        PlaceID: this.$route.params.id,
                        EmployeeID: userId,
                        ProductID: productId,
                        Count: parseInt(count) || 0,
                        Sum: String(parseFloat(total) || 0),
                    }
                }))
            }, [])
        },

        onChangeValue(val) {
            val.forEach(sale => {
                const employee = this.model[sale.EmployeeID]
                if (!employee) return

                const product = employee[sale.ProductID]
                if (!product) return
                // eslint-disable-next-line no-console
                product.count = parseFloat(sale.Count) || ""
                product.total = parseFloat(sale.Sum) || ""
            })
        },
    },
}
</script>
