<template>
    <div class="d-flex report-product-form align-center">
        <input :disabled="disabled"
               v-model="model.count"
               class="report-product-form__field"
               :placeholder="$t(`labels['amount']`)"
               type="number" min="0"/>
        <div class="report-product-form__spacer"></div>
        <input :disabled="disabled"
               v-model="model.total"
               class="report-product-form__field"
               :placeholder="$t(`labels['sum']`)"
               type="number" min="0"/>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({ total: "", count: "" }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            model: Object.assign({}, this.value),
        }
    },

    mounted() {
        this.$watch("model.total", this.onChangeVal.bind(this, "total"))
        this.$watch("model.count", this.onChangeVal.bind(this, "count"))
    },

    methods: {
        onChangeVal(property, val) {
            const value = Object.assign({}, this.value, { [property]: val })
            this.$emit("input", value)
        },
    },
}
</script>
<style>
    .report-product-form {
        height: 100%;
    }

    .report-product-form__spacer {
        margin: 0 4px;
        height: 100%;
        background: #e0e0e0;
        width: 1px;
    }

    .report-product-form__field {
        width: 70px;
        border: 1px solid #546e7a;
        height: 25px;
    }

    .report-product-form__field {
        -moz-appearance: textfield;
    }

    .report-product-form__field::-webkit-outer-spin-button,
    .report-product-form__field::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .report-product-form__field[disabled] {
        background: #dcdcdc;
    }
</style>
