<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="6">
                <h4 class="headline" :class="{'subtitle-1': $vuetify.breakpoint.smAndDown}">
                    <slot>{{ $t(`tabs['photos control']`) }}</slot>
                </h4>
            </v-col>
            <v-col cols="6" align="end">
                <div>
                    <v-btn :to="`/upload/?place=${placeId}`" type="submit" text ripple color="primary">
                        <span v-if="$vuetify.breakpoint.xsOnly">
                            <v-icon left>mdi-cloud-upload-outline</v-icon>
                        </span>

                        <span v-else>
                            <v-icon left>mdi-cloud-upload-outline</v-icon>
                            {{ $t("actions.upload") }}
                        </span>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-switch inset :label="$t(`labels['show sessions']`)" v-model="photoreportsView"/>
        <div v-if="!photoreportsView">
            <keep-alive>
                <photogroups-control :place-id="placeId" :photographer-scope="photographerScope"/>
            </keep-alive>
        </div>
        <div v-if="photoreportsView">
            <keep-alive>
                <photoreports-control :place-id="placeId" :photographer-scope="photographerScope"/>
            </keep-alive>
        </div>
    </v-container>
</template>

<script>
import PhotogroupsControl from "./PhotogroupsControl"
import PhotoreportsControl from "./PhotoreportsControl"

export default {
    components: { PhotogroupsControl, PhotoreportsControl },
    props: {
        placeId: {
            type: String,
            default: "",
        },
        photographerScope: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        photoreportsView(val) {
            const query = { mode: val ? "photoreports" : "photos" }
            const route = Object.assign({}, this.$route, { query })
            this.$router.replace(route)
        },
    },
    data() {
        return {
            photoreportsView: this.$route.query.mode === "photoreports",
        }
    },
}
</script>
