<template>
    <v-tabs centered grow class="gray-tabs" slider-color="orange" background-color="blue-grey lighten-5" v-model="tab">
        <v-tab>
            {{ $t(`tabs['admins']`) }}
        </v-tab>

        <v-tab>
            {{ $t(`tabs['photographers']`) }}
        </v-tab>

        <v-tab>
            {{ $t(`tabs['sellers']`) }}
        </v-tab>

        <v-tab>
            {{ $t(`tabs['retouchers']`) }}
        </v-tab>

        <v-tabs-items v-model="tab">

            <v-tab-item :reverse-transition="false" :transition="false">
                <admins-controller :place-id="placeId"/>
            </v-tab-item>

            <v-tab-item :reverse-transition="false" :transition="false">
                <photographers-controller :place-id="placeId"/>
            </v-tab-item>

            <v-tab-item :reverse-transition="false" :transition="false">
                <sellers-controller :place-id="placeId"/>
            </v-tab-item>

            <v-tab-item :reverse-transition="false" :transition="false">
                <retouchers-controller :place-id="placeId" />
            </v-tab-item>
        </v-tabs-items>

    </v-tabs>
</template>

<script>
import PhotographersController from "./PhotographersController"
import AdminsController from "./AdminsController"
import SellersController from "./SellersController"
import RetouchersController from "./RetouchersController"

export default {
    components: { PhotographersController, AdminsController, SellersController, RetouchersController },
    props: {
        placeId: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            tab: 0,
        }
    },
}
</script>
