import { mapActions } from "vuex"
import * as Format from "../../util/format"

const defaultShare = () => ({
    id: null,
    share: null,
})

export default (role = "") => ({
    props: {
        placeId: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            place: {},
            loading: false,
            errorLoading: false,
            list: [],
            visibleEditShare: false,
            shareModel: defaultShare(),
        }
    },

    mounted() {
        this.loadList()
    },

    computed: {
        getDeclinedRoleName() {
            if (role === "admin") {
                return this.$t(`roles.admn`)
            } else if (role === "photographer") {
                return this.$t(`roles.phtgr`)
            } else if (role === "retoucher") {
                return this.$t(`roles.rtchr`)
            } else if (role === "seller") {
                return this.$t(`roles.slr`)
            } else {
                return this.$t(`roles.emp`)
            }
        },

        getCapitalizedRole() {
            return role.charAt(0).toUpperCase() + role.slice(1)
        },

        getCapitalizedPluralRole() {
            return this.getCapitalizedRole.concat("s")
        },

        getEmployees() {
            const sharesName = role === "photographer" ? "EmployeesShares" : `${this.getCapitalizedPluralRole}Shares`
            const shares = (this.place || {})[sharesName] || {}


            return this.list.map(user => ({
                name: Format.getUserName(user.Name),
                share: role === "retoucher" ? (this.place.RetouchersShare || 0) : (shares[user.ID] || 0),
                id: user.ID,
                image: Format.getMedia(user.Avatar.Cropped),
            }))
        },
    },

    methods: {
        ...mapActions("place", [
            "addAdmin",
            "removeAdmin",
            "updateAdminShare",
            "addPhotographer",
            "removePhotographer",
            "updatePhotographerShare",
            "addRetoucher",
            "removeRetoucher",
            "updateRetouchersShare",
            "addSeller",
            "removeSeller",
            "updateSellerShare",
        ]),

        ...mapActions({
            getUsers: "user/getByIds",
            getPlace: "place/getById",
        }),

        addEmployee(payload) {
            if (role === "admin") {
                return this.addAdmin(payload)
            } else if (role === "photographer") {
                return this.addPhotographer(payload)
            } else if (role === "retoucher") {
                return this.addRetoucher(payload)
            } else if (role === "seller") {
                return this.addSeller(payload)
            }
        },

        removeEmployee(payload) {
            if (role === "admin") {
                return this.removeAdmin(payload)
            } else if (role === "photographer") {
                return this.removePhotographer(payload)
            } else if (role === "retoucher") {
                return this.removeRetoucher(payload)
            } else if (role === "seller") {
                return this.removeSeller(payload)
            }
        },

        editShare(payload) {
            if (role === "admin") {
                return this.updateAdminShare(payload)
            } else if (role === "photographer") {
                return this.updatePhotographerShare(payload)
            } else if (role === "retoucher") {
                return this.updateRetouchersShare(payload)
            } else if (role === "seller") {
                return this.updateSellerShare(payload)
            }
        },

        loadList() {
            this.errorLoading = false
            this.loading = true
            return this.getPlace(this.placeId).then(place => {
                this.place = place
                return this.getUsers(place[this.getCapitalizedPluralRole])
            }).then(list => {
                this.list = list
            }).catch(() => {
                this.errorLoading = true
            }).finally(() => {
                this.loading = false
            })
        },

        onClickAdd() {
            this.$dialog.selectUser({ title: this.$t(`rudiments['add']`, [this.getDeclinedRoleName]) })
                .then(user => user && this.confirmAdd(user.ID))
        },

        confirmAdd(id) {
            const payload = { ID: this.place.ID, UserID: id }
            this.$dialog.loading({ text: this.$t(`rudiments.adding`, [this.getDeclinedRoleName]) }).then(dialog => {
                this.addEmployee(payload)
                    .then(() => this.loadList())
                    .catch(() => {
                        this.$dialog.message.error(this.$t(`errors['add error']`, [this.getDeclinedRoleName]))
                    })
                    .finally(() => dialog.close())
            })
        },

        onClickRemove(id) {
            const employee = this.list.find(e => e.ID === id)

            this.$dialog.confirm({
                title: this.$t(`rudiments['sure']`),
                text: this.$t(`rudiments.fire`, [this.getDeclinedRoleName, Format.getUserName(employee.Name)]),
            }).then(result => result && this.confirmRemove(id))
        },

        confirmRemove(id) {
            const payload = {
                ID: this.place.ID,
                UserID: id,
            }

            this.$dialog.loading({ text: this.$t(`messages['delete progress']`) }).then((dialog) => {
                this.removeEmployee(payload)
                    .then(() => this.loadList())
                    .catch(() => {
                        this.$dialog.message.error(this.$t(`errors['delete error']`, [this.getDeclinedRoleName]))
                    }).finally(() => dialog.close())
            })
        },

        onClickEdit(share, id = "") {
            this.shareModel = { id, share }
            this.visibleEditShare = true
        },

        confirmEdit() {
            this.visibleEditShare = false
            const { id, share } = this.shareModel
            const payload = {
                ID: this.place.ID,
                Share: share,
                UserID: id,
            }

            this.$dialog.loading({ text: this.$t(`messages['share progress']`) }).then(dialog => {
                this.editShare(payload)
                    .then(() => this.loadList())
                    .catch(() => {
                        this.$dialog.message.error(this.$t(`errors['share update error']`))
                    })
                    .finally(() => dialog.close())
            })
        },

    },
})
